import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./CustomSelect.scss"; // Custom CSS for styling

const ListMenuComponent = ({
  options = [],
  handleSelectChange = () => {},
  openNestedMenus = {},
  toggleNestedMenu = () => {},
}) => {
  return (
    <ul className="list-group custom-select-dropdown">
      {options.length > 0 ? (
        options.map((option, index) => (
          <li key={option.value || index} className="list-group-item">
            <div
              className="custom-select-title"
              onClick={() => {
                if (option.option) {
                  toggleNestedMenu(option.value);
                } else {
                  handleSelectChange(option);
                }
              }}
            >
              <span>{option?.label || ""}</span>
              {option.option && (
                <FontAwesomeIcon
                  icon={
                    openNestedMenus[option.value] ? faChevronUp : faChevronDown
                  }
                />
              )}
            </div>
            {option.option && openNestedMenus[option.value] && (
              <div className="nexted-inner-list">
                <ListMenuComponent
                  options={option.option}
                  handleSelectChange={handleSelectChange}
                  openNestedMenus={openNestedMenus}
                  toggleNestedMenu={toggleNestedMenu}
                />
              </div>
            )}
          </li>
        ))
      ) : (
        <li className="list-group-item">No results found</li>
      )}
    </ul>
  );
};

const CustomSelect = ({
  options = [
    { value: "1", label: "Lead ID" },
    { value: "2", label: "Registration Number" },
    { value: "3", label: "Phone Number" },
    { value: "4", label: "Engine Number" },
    { value: "5", label: "Chassis Number" },
    // {
    //   value: "4",
    //   label: "Fourth More Option",
    //   option: [
    //     { value: "5", label: "Nested Option" },
    //     { value: "6", label: "2 Nested Option" },
    //   ],
    // },
    // {
    //   value: "7",
    //   label: "Fifth More Option",
    //   option: [
    //     {
    //       value: "6",
    //       label: "1 parent",
    //       option: [{ value: "5", label: "1 child" }],
    //     },
    //   ],
    // },
  ],
  placeholder = "Open this select menu",
  // dropdownRef,
  onChange = () => {},
  onSearch = () => {},
  className = "",
  style = {},
  value = null,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openNestedMenus, setOpenNestedMenus] = useState({});
  const dropdownRef = useRef(null);

  const handleSearchChange = (event) => {
    onSearch(event?.target?.value);
    if (!event?.target?.value) {
      console.log("-------------->>>>>>");
      setSearchTerm("");
      onChange({});
      return;
    }
    setSearchTerm(event?.target?.value);
  };

  const handleSelectChange = (value) => {
    setSearchTerm(value?.label);
    setIsOpen(false);
    onChange(value);
  };

  const handleToggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleNestedMenu = (value) => {
    setOpenNestedMenus((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`custom-select ${className}`}
      {...props}
      ref={dropdownRef}
      style={style}
    >
      <div className="custom-select-input-wrapper">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={searchTerm || value?.label}
          onChange={handleSearchChange}
          onFocus={() => setIsOpen(true)}
          style={{ height: "40px", borderRadius: "12px" }}
        />
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className="dropdown-icon"
          onClick={handleToggleDropdown}
        />
        {isOpen && (
          <ListMenuComponent
            options={filteredOptions}
            handleSelectChange={handleSelectChange}
            openNestedMenus={openNestedMenus}
            toggleNestedMenu={toggleNestedMenu}
          />
        )}
      </div>
    </div>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      option: PropTypes.array,
    })
  ),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  // value: PropTypes.shape({
  //   value: PropTypes.string.isRequired,
  //   label: PropTypes.string.isRequired,
  // }),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CustomSelect;
