import React, { useEffect, useState } from "react";
import CustomTable from "../../../../shared/CustomTable";
import { Label } from "@mui/icons-material";

const LeadManagementInteractionHistory = ({ tableRow = [] }) => {
  const [tableDataRowList, setTableDataRowList] = useState([]);

  const tableColumnDataList = [
    {
      id: 1,
      label: "Mode",
      rowId: "Mode",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 2,
      label: "Disposition",
      rowId: "Call_status",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 3,
      label: "CRE",
      rowId: "CRE",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 4,
      label: "date",
      rowId: "policy_end_date",
      position: "center",
      isRenderItem: false,
    },
    {
      id: 5,
      label: "Remark",
      rowId: "remarks",
      position: "center",
      isRenderItem: false,
    },
  ];

  useEffect(() => {
    const updatedTableDataList = tableRow?.map((value) => ({
      ...value,
      //   modeRender: modeRenderFun(value),
    }));
    setTableDataRowList(updatedTableDataList);
  }, [tableRow]);
  return (
    <div>
      <CustomTable
        tableColumn={tableColumnDataList}
        tableRow={tableDataRowList}
      />
    </div>
  );
};

export default LeadManagementInteractionHistory;
