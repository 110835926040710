import React, { useCallback, useEffect, useState } from "react";
import "./InboundLead.scss";
import ROUTES_NAVIGATION from "../../../../routes/routes";
import MainCard from "../../../shared/MainCard";
import CustomButton from "../../../shared/CustomButton";
import CustomSelect from "../../../shared/CustomSelect";
import CustomInput from "../../../shared/CustomInput";
import CustomSearch from "../../../shared/CustomSearch";
import InboundTableList from "./InboundListTable";
import { INBOUND_LIST_DATA } from "./InboundListDefaultData";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomDateInput from "../../../shared/CustomDateInput";
import moment from "moment";
import { mdiPlus } from "@mdi/js";
import { Icon } from "@mui/material";
import AddLeadComponent from "../AllLeadsComponent/AddLeadComponent";
import CustomModal from "../../../shared/CustomModal";
import InboundInfo from "./InboundLeadData/InboundInfo";
import { faL } from "@fortawesome/free-solid-svg-icons";
import ExistingLeadModelComponant from "./InboundLeadData/ExistingLeadModelComponant";
import { APIInboundMobNumber } from "../../../../api/common";
import ExistLeadTableList from "./InboundLeadData/ExistLeadTableList";
import { displayToast } from "../../../shared/toast/toastUtils";

const InboundLeadListComponent = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();
  const [isAddTaskListModal, setIsAddTaskListModal] = useState(false);

  const [searchParams] = useSearchParams();
  const [isInfoModelOpen, setIsinfoModelOpen] = useState(false);
  const mobileNumber = searchParams.get("mobile");
  const [isExistLead, setIsExistLead] = useState(false);
  const [statusRes, setResStatus] = useState();
  console.log("mobileNumber---->", mobileNumber);
  console.log("status---->", statusRes);

  // for (const mobile of inboundList) {
  //   console.log("<><><><><", mobile?.mobile);
  //   if (mobile?.mobile === mobileNumber) {
  //     setIsExistLead(true);
  //   }
  // }

  const PAGE_ROUTE = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_INBOUND_LEAD,
      label: "Inbound Lead",
    },
  ];

  const [existLeadList, setExistLeadList] = useState([]);

  // const navigate = useNavigate();

  const getRecAgainstMobNumber = useCallback(async () => {
    const paylaod = {
      // mobile_no: "7218903021" || null,
      mobile_no: mobileNumber || null,
    };
    const res = await APIInboundMobNumber(paylaod);
    setResStatus(res?.data?.status_code);
    if (res.ok) {
      setExistLeadList(res?.data?.data || []);
    } else {
      // searchResponse(res);
      setExistLeadList([]);
      displayToast({
        type: "error",
        title: res?.data?.message,
      });
    }
  }, [mobileNumber]);

  useEffect(() => {
    getRecAgainstMobNumber();
  }, [getRecAgainstMobNumber]);

  // if (mobileNumber) {
  //   if (statusRes === 200) {
  //     console.log("200 executed", statusRes);
  //     setIsExistLead(true);
  //   }
  //   if (statusRes === 404) {
  //     setIsAddTaskListModal(true);
  //     console.log("404 executed", statusRes);
  //   }
  // }

  // useEffect(() => {
  //   mobileNumber ? setIsExistLead(true) : setIsAddTaskListModal(true);
  // }, [mobileNumber]);
  // useEffect(() => {
  //   if (mobileNumber) {
  //     if (status === 200) {
  //       console.log("200 executed", status);
  //       setIsExistLead(true);
  //     }
  //     if (status === 404) {
  //       setIsAddTaskListModal(true);
  //       console.log("404 executed", status);
  //     }
  //   }
  // }, [mobileNumber, status]);
  useEffect(() => {
    if (mobileNumber) {
      if (statusRes === 200) {
        setIsExistLead(true);
      } else if (statusRes === 404) {
        setIsAddTaskListModal(true);
      }
    }
  }, [statusRes, mobileNumber]);

  return (
    <MainCard
      title="Inbound Lead"
      pageRoutes={PAGE_ROUTE}
      activeButtons={
        <div className="d-flex gap-2 inbound-main">
          {/* <CustomButton
            className={"rounded-2  qutation-btn"}
            style={{
              fontSize: "0.8rem",
            }}
            onClick={() => {
              setIsModalOpen(true);
              // setIsAddTaskListModal(true);
            }}
          >
            <i className="bi bi-card-list"></i>
            Send Questionnaire
          </CustomButton> */}
          {/* <CustomButton
            className={"rounded-2 text-dark-teal"}
            onClick={() => {
              // setIsAddTaskListModal(true);
            }}
          >
            <i className="bi bi-three-dots-vertical"></i>
          </CustomButton> */}
          {/* <CustomButton
            className={"text-blue rounded-2 bg-teal"}
            onClick={() => {
              // setIsAddTaskListModal(true);
            }}
          >
            <i className="bi bi-plus"></i>
          </CustomButton> */}
          {/* <div className="me-1">
            <CustomButton className="btn download-btn">
              <i className="bi bi-download"></i>
              <span className="">Download</span>
            </CustomButton>
          </div> */}
        </div>
      }
    >
      <div className="">
        <div className=" row pb-3 d-flex">
          {/* <div className="row g-2 "> */}
          {/* <div className="col-12  col-sm-4 col-md-3 col-lg-2">
              <CustomSelect placeholder={"IMD Code"} />
            </div> */}
          {/* <div className="col-12  col-sm-4 col-md-3 col-lg-2">
              <CustomSelect placeholder={"Engine Number"} />
            </div> */}
          {/* <div className="col-12 col-sm-4 col-md-3 col-lg-8"> */}
          <div className="col-6">
            <CustomSearch />
          </div>
          <div className="col-6 d-flex">
            <div className="d-flex align-items-center me-auto">
              <div className="me-2 d-flex align-items-center">
                <CustomDateInput
                  label="From"
                  placeholderText="DD/MM/YYYY"
                  isDateRange={false}
                  className="from-date-input ms-1"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(
                      moment(date, [
                        "YYYY-MM-DD",
                        "YYYY-DD-MM",
                        "DD-MM-YYYY",
                      ]).format("yyyy-MM-DD")
                    );
                  }}
                />
              </div>
              <div className="me-2 d-flex align-items-center">
                <CustomDateInput
                  label="To"
                  placeholderText="DD/MM/YYYY"
                  isDateRange={false}
                  className="to-date-input ms-1"
                  selected={endDate}
                  disabled={!startDate}
                  onChange={(date) => {
                    setEndDate(
                      moment(date, [
                        "YYYY-MM-DD",
                        "YYYY-DD-MM",
                        "DD-MM-YYYY",
                      ]).format("yyyy-MM-DD") || null
                    );
                  }}
                  // minDate={startDate}
                />
              </div>
              <button
                className="btn"
                style={{
                  backgroundColor: "rgba(231, 240, 255, 1)",
                  borderRadius: "10px",
                }}
                // onClick={() => clickOnReset()}
                // onClick={() => resetFun()}
              >
                <i
                  className="bi bi-arrow-clockwise"
                  style={{ color: "rgba(66, 133, 244, 1)" }}
                />
              </button>
              <div className="px-3">
                <CustomButton
                  textColor="white"
                  // tooltip={"Add Lead"}
                  placement="bottom"
                  className="btn border rounded plus-button bg-primary"
                  onClick={() => setIsAddTaskListModal(true)}
                >
                  <i className="bi bi-plus text-white"></i>
                  Add Lead
                </CustomButton>
              </div>
              {/* <div className=" d-flex flex-wrap gap-2">
                <CustomButton
                  className={
                    "rounded-2 gap-2 px-3 text-blue qutation-btn bg-teal"
                  }
                  onClick={() => {
                    // setOpenFourWheelerAddModal(true);
                  }}
                >
                  <Icon path={mdiPlus} size={0.8} />
                  <div className="text-blue font-8">Add Lead</div>
                </CustomButton>
              </div> */}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="card mb-4 pt-3">
        {/* <InboundTableList
          tableRow={inboundList}
          // onLeadIDClick={(row) => {
          //   navigate(
          //     ROUTES_NAVIGATION.VEHICLE_INSURENCE_LEAD_DETAILS + "?id=" + row.id
          //   );
          // }}
        /> */}
        {/* <p>
          <small>
            <strong> Receiving Incoming Call from +91 - 8787878787 </strong>
            <br />
            Our records indicate that this number is associated with the
            following entries. Please select one to proceed
          </small>
        </p> */}
        <ExistLeadTableList />
      </div>
      <CustomModal
        show={isAddTaskListModal}
        title="Add Lead"
        handleClose={() => {
          setIsAddTaskListModal(false);
          setIsinfoModelOpen(true);
        }}
      >
        <AddLeadComponent
          mobileNumber={mobileNumber}
          onCancel={() => setIsAddTaskListModal(false)}
          onSave={() => {
            setIsAddTaskListModal(false);
            // getAPITaskListHandler();
            // APILeadCountHandler();
            navigate(ROUTES_NAVIGATION.VEHICLE_INSURENCE_ALL_LEADS);
          }}
        />
      </CustomModal>
      <CustomModal
        show={isInfoModelOpen}
        title="Inbound Call"
        // handleClose={() => {
        //   setIsinfoModelOpen(false);
        // }}
        keyboard={false}
        backdrop="static"
      >
        <InboundInfo mobileNumber={mobileNumber} />
      </CustomModal>
      <CustomModal
        show={isExistLead}
        title="Exist Lead List"
        // handleClose={() => {
        //   setIsExistLead(false);
        // }}
      >
        <ExistingLeadModelComponant
          mobileNumber={mobileNumber}
          existLeadList={existLeadList}
          // searchResponse={(res) => {
          //   console.log("ressssponseeee", res?.data?.status_code);
          // }}
        />
      </CustomModal>
    </MainCard>
  );
};

export default InboundLeadListComponent;
