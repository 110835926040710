import React, { useCallback, useContext, useEffect, useState } from "react";

import MainCard from "../../../shared/MainCard";
import ROUTES_NAVIGATION from "../../../../routes/routes";
import "./LeadManagement.scss"; // Ensure the CSS file is correctly imported
// import Accordion from "react-bootstrap/Accordion";
import PolicyDetailsComponent from "./PolicyDetailsComponent";
import LeadManagmentStatusComponent from "./LeadManagmentStatusComponent";
import LeadManagmentCallStatusComponent from "./LeadManagmentCallStatusComponent";
import CustomButton from "../../../shared/CustomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import CallDetailsComponent from "./CallDetailsComponent";
import CustomModal from "../../../shared/CustomModal";
import SelectMassageTypeComponent from "./SelectMassageTypeComponent";
import {
  APIAlternateNumber,
  APIDialler,
  APIEditLeadDetails,
  APIgetDataSMSMail,
  APIgetDispositionLeadDetails,
  APIgetICOption,
  APIgetInteractionOption,
  APIgetleadDetailsForTelecaller,
  APIgetLeadStatusUpdate,
  APIgetQuotFromGiib,
  APIgetRto,
  APIPolicyStatus,
} from "../../../../api/common";
import GlobalContext from "../../../../context/GlobalContext";
import { debounce, hasIn } from "lodash";
import usePrompt from "./PolicyDetailsComponent/usePrompt";
import AddLeadComponent from "../AllLeadsComponent/AddLeadComponent";
import { useLoader } from "../../../../context/LoaderProvider";
import EditLeadInformation from "../AllLeadsComponent/EditLeadDetailsComponent";
import { CleaningServices, Height, Padding } from "@mui/icons-material";
import { displayToast } from "../../../shared/toast/toastUtils";
import CallSelectionModal from "./PolicyDetailsComponent/CallModal";
import { EditLeadDetails } from "../../../../redux/FormData/reduxFormManage";
import { dispatch } from "../../../../redux";
import CustomSelect from "../../../shared/CustomSelect";
import { Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { DISPOSITION_VIA_OPTION } from "../../../../constants/utils";

const LeadManagementComponent = () => {
  const navigate = useNavigate();
  const [showCallDetails, setShowCallDetails] = useState(false);
  const [isSelectMassageType, setIsSelectMassageType] = useState(false);
  const [leadDisaposition, setLeadDisposition] = useState([]);
  const [leadDispositionForTel, setLeadDispositionForTel] = useState([]);
  const [searchParams] = useSearchParams();
  const [parentRecall, setParentRecall] = useState(false);
  const [hideCallMsgBtn, setHideCallMsgBtn] = useState(false);
  const [leadMobNo, setLeadMobNo] = useState(null);
  const [leadPolicyNo, setLeadPolicyNo] = useState();
  const [isAddTaskListModal, setIsAddTaskListModal] = useState(false);
  const [leadStatus, setLeadStatus] = useState();
  const [isEditLead, setIsEditLead] = useState(false);
  const [leadName, setLeadName] = useState();
  const [ic, setIc] = useState();
  const [regNumber, setRegnumber] = useState();
  const [policyEndDate, setPolicyEndDate] = useState();
  const [dataForSMSMail, setDataForSMSMail] = useState(null);
  const [error, setError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [callId, setCallId] = useState();
  const [isInitiateCallButtonShow, setIsInitiateCallButtonShow] =
    useState(null);

  console.log("isInitiateCallButtonShow", isInitiateCallButtonShow);

  // const [isCallInitiated, setIscallInitiated] = useState(() => {
  //   return JSON.parse(localStorage.getItem("isCallInitiated")) || false;
  // });
  const [isCallInitiated, setIscallInitiated] = useState(false);
  const [isCallDisposed, setIsCallDisposed] = useState(false);
  const { setShowLoader } = useLoader();
  const [isCallModalVisible, setIsCallModalVisible] = useState(false);
  const [selectedMobileNumber, setSelectedMobileNumber] = useState(null);
  const { hasAccess } = useContext(GlobalContext);
  const quotation = searchParams.get("details");
  const productId = searchParams.get("productId");
  const leadId = searchParams.get("leadId");
  const role = localStorage.getItem("role");
  const [handleSave, setHandleSave] = useState(false);
  const [disableFuncForHistory, setDisableFuncForHistory] = useState(false);
  const [saveAlternateNumber, setSaveAlternateNumber] = useState(false);
  const [linkForSMS, setLinkForSMS] = useState(null);
  const [planDetails, setPlansDetails] = useState();
  const [inetactionTypeOption, setinteractionTypeOption] = useState(null);
  const [interactionType, setInteractionType] = useState(null);

  // const quotation = searchParams.get("details");

  const getDataForMailSMS = useCallback(async () => {
    const paylaod = {
      leadId: leadId,
    };
    setShowLoader(true);
    const res = await APIgetDataSMSMail(paylaod);
    setShowLoader(false);
    if (res?.ok) {
      setDataForSMSMail(res?.data?.data);
    } else {
      setDataForSMSMail({});
    }
  }, [leadId, setShowLoader]);

  const getDispositionDetailsHandler = useCallback(async () => {
    setShowLoader(true);
    const result = await APIgetDispositionLeadDetails(leadId);
    setShowLoader(false);

    if (result?.ok) {
      setLeadDisposition(result?.data?.data);
    } else {
      setLeadDisposition([]);
    }
  }, [leadId, setShowLoader]);

  const getLeadDetailsForTellecallerHandler = useCallback(async () => {
    setShowLoader(true);
    const result = await APIgetleadDetailsForTelecaller();
    setShowLoader(false);

    try {
      if (result?.ok && result?.data?.data) {
        setLeadDispositionForTel(result?.data?.data);
      } else {
        setLeadDispositionForTel([]);
      }
    } catch (error) {
      setLeadDispositionForTel([]);
    }
  }, [setShowLoader]);

  const mobNoFromChild = (mobNo) => {
    setLeadMobNo(mobNo);
  };

  const policyNumberFromChild = (policyNo) => {
    setLeadPolicyNo(policyNo);
  };

  const handleCallModalToggle = () => {
    setShowCallDetails(false);
    setIsCallModalVisible(!isCallModalVisible);
  };
  // Simulated call initiation API method
  const handleInitiateCall = (selectedNumber) => {
    if (!selectedNumber) {
      alert("Please select a mobile number first.");
      return;
    }
    setIscallInitiated(true);
    alert(`Call initiated to ${selectedNumber}`);
    setTimeout(() => {
      setIscallInitiated(false);
      alert("Call completed!");
    }, 3000);
  };

  const getStatusList = useCallback(async (leadId) => {
    const resp = await APIPolicyStatus(leadId);
  }, []);

  const getLeadStatus = useCallback(async () => {
    setShowLoader(true);
    const res = await APIgetLeadStatusUpdate(leadId);
    setShowLoader(false);
    if (res?.ok) {
      setLeadStatus(res?.data?.data || null);
    } else {
      setLeadStatus(null);
    }
  }, [leadId, setShowLoader]);

  const handleSaveClick = () => {
    // Toggle the state to trigger the useEffect
    setParentRecall((prev) => !prev);
    // setStatusDetails()
    getStatusList(leadId);
    setIsCallDisposed(true);
    setIscallInitiated(false);
    getDispositionDetailsHandler();
    getLeadStatus();
  };

  useEffect(() => {
    getLeadStatus();
  }, [getLeadStatus]);

  useEffect(() => {
    getStatusList(leadId);
  }, [getStatusList, leadId]);

  useEffect(() => {
    if (role === "Telecaller") {
      getLeadDetailsForTellecallerHandler();
    }
  }, [getLeadDetailsForTellecallerHandler, role]);

  useEffect(() => {
    if (!showCallDetails) {
      getDispositionDetailsHandler(leadId);
    }
  }, [getDispositionDetailsHandler, leadId, showCallDetails]);

  const status = leadStatus?.isContacted
    ? leadStatus?.isQuotationSent
      ? leadStatus?.isProposalSent
        ? leadStatus?.isPaymentDone
          ? 6
          : 5
        : 4
      : 3
    : 1;

  const getLeadFlagColorFlag = (value) => {
    let flagColor = "";
    if (!value?.leadType) {
      flagColor = "n/a";
    } else {
      switch (value?.leadType?.toLowerCase()) {
        case "cold":
          flagColor = "text-warning";
          break;
        case "warm":
          flagColor = "text-info";
          break;
        case "hot":
          flagColor = "text-danger";
          break;
        default:
          flagColor = "";
      }
    }
    return flagColor;
  };

  const leadFlagColor = getLeadFlagColorFlag(leadDisaposition);

  useEffect(() => {
    localStorage.setItem("isCallInitiated", JSON.stringify(isCallInitiated));
    localStorage.setItem("isCallDisposed", JSON.stringify(isCallDisposed));
  }, [isCallDisposed, isCallInitiated]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isCallInitiated) {
        event.preventDefault();
        event.returnValue =
          "You have an ongoing call. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isCallInitiated]);

  const handleNavigate = (path) => {
    if (isCallInitiated) {
      const confirmLeave = window.confirm(
        "You have an ongoing call. Are you sure you want to leave?"
      );
      if (!confirmLeave) return;
    }
    navigate(path);
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^[6789]\d{0,9}$/.test(value) || value === "") {
      setMobileNumber(value);
      setError(""); // Clear error if the input is valid
    } else {
      setError(
        "Number must start with 6, 7, 8, or 9 and have up to 10 digits."
      );
    }
  };
  const handleSaveAlternateNumber = async () => {
    const payload = { alternate_mobile: mobileNumber };
    const res = await APIAlternateNumber(leadId, payload);
    setSaveAlternateNumber(true);
    if (res?.ok) {
      setShowModal(false);
      displayToast({
        type: "success",
        title: res?.data?.message,
      });

      setLeadDisposition((prev) => ({
        ...prev,
        alternate_mobile: mobileNumber,
      }));
    } else {
      displayToast({
        type: "error",
        title: res?.data?.message || "Something went wrong",
      });
    }
  };

  const getInteractionOption = async () => {
    const res = await APIgetInteractionOption();
    if (res?.ok) {
      setinteractionTypeOption(res?.data?.data);
    } else {
      setinteractionTypeOption([]);
    }
  };

  const getEditLeadDetailsData = useCallback(async (editLeadId) => {
    const payload = {
      leadId: editLeadId,
    };
    const res = await APIEditLeadDetails(payload);
    if (res?.ok) {
      dispatch(EditLeadDetails(res?.data?.data));
    } else {
      dispatch(EditLeadDetails({}));
    }
  }, []);

  useEffect(() => {
    if (isEditLead) getEditLeadDetailsData(leadId);
  }, [getEditLeadDetailsData, isEditLead, leadId]);

  useEffect(() => {
    if (leadId) {
      getDataForMailSMS();
    }
  }, [getDataForMailSMS, leadId]);

  useEffect(() => {
    if (isInitiateCallButtonShow === "Without Initiate Call") {
      getInteractionOption();
    }
  }, [isInitiateCallButtonShow]);

  return (
    <MainCard title="Leads Details" isBack onBack={() => handleNavigate(-1)}>
      <div className="card lead-managementMainCard">
        <div className="lead-details-header-sub-container p-3 px-5">
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Lead Id</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.leadId}
            </div>
          </div>
          {leadDisaposition?.policyType !== "New" && (
            <div className="lead-details-header border-end ">
              <div className="lead-management-header-row">CRE</div>
              <div className="lead-management-data-row">
                {leadDisaposition?.cre}
              </div>
            </div>
          )}
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Customer</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.leadname}
            </div>
          </div>
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Product Type</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.ProductType}
            </div>
          </div>
          <div className="lead-details-header border-end ">
            <div className="lead-management-header-row">Policy Type</div>
            <div className="lead-management-data-row">
              {leadDisaposition?.policyType}
            </div>
          </div>
          {leadDisaposition?.policyType !== "New" && (
            <div className="lead-details-header border-end ">
              <div className="lead-management-header-row">Policy Status</div>
              <div className="lead-management-data-row">
                {leadDisaposition?.policyStatus}
              </div>
            </div>
          )}
          <div className="lead-details-header ">
            <div className="lead-management-header-row">Lead Type</div>
            <div className="lead-management-data-row">
              {/* {leadDisaposition?.leadType}
              <i className={`bi bi-flag-fill ${leadFlagColor}`}></i> */}
              {leadDisaposition?.leadType || "N/A"}

              {leadFlagColor && leadFlagColor !== "n/a" && (
                <i className={`bi bi-flag-fill ${leadFlagColor}`}></i>
              )}
              {/* {leadFlagColor === "n/a" && <></>} */}
            </div>
          </div>

          <div className="lead-details-header d-flex align-items-center">
            <div className="d-flex px-1">
              {hasAccess("HAS_LM_ADD_LEADS") && (
                <div className="px-1 mt-1">
                  <CustomButton
                    tooltip={"Add Lead"}
                    placement="bottom"
                    className="btn border rounded plus-button bg-primary"
                    onClick={() => setIsAddTaskListModal(true)}
                  >
                    <i className="bi bi-plus text-white"></i>
                  </CustomButton>
                </div>
              )}

              {hasAccess("HAS_LM_EDIT_LEADS") && (
                <div className="px-1 mt-1">
                  <CustomButton
                    tooltip={"Edit Lead Details"}
                    placement="bottom"
                    className="btn border rounded plus-button bg-primary"
                    onClick={() => setIsEditLead(true)}
                  >
                    <i class="bi bi-pencil-square text-white"></i>
                  </CustomButton>
                </div>
              )}

              <div className="title-btn px-0 mt-1">
                <CustomButton
                  tooltip={""}
                  className="custom-button bg-yellow text-blue fw-bold"
                  onClick={(row) => {
                    navigate(
                      ROUTES_NAVIGATION.VEHICLE_INSURENCE_DMS_DETAILS +
                        "?id=" +
                        leadId +
                        "&product=" +
                        productId
                    );
                  }}
                >
                  Upload Document
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <PolicyDetailsComponent
            leadDispositionForTel={leadDispositionForTel}
            leadName={() => setLeadName()}
            icName={() => setIc()}
            vehRegNumber={() => setRegnumber()}
            onSendNumber={mobNoFromChild}
            onSendPolicyNo={policyNumberFromChild}
            // handleSaveAlternateNumber={handleSaveAlternateNumber}
            saveAlternateNumber={saveAlternateNumber}
            onclickSetLink={(link) => setLinkForSMS(link)}
            onSavePalnsDetails={(value) => setPlansDetails(value)}
            isMessageSend={() => setIsSelectMassageType(true)}
          />
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <LeadManagmentStatusComponent
                  currentStatus={status}
                  options={[
                    { id: 1, label: "Qualified" },
                    { id: 2, label: "Contacted", keys: "isContacted" },
                    { id: 3, label: "Quotations", keys: "isQuotationSent" },
                    { id: 4, label: "Proposal Sent", keys: "isProposalSent" },
                    { id: 5, label: "Payment", keys: "isPaymentDone" },
                    { id: 6, label: "Converted", keys: "isPaymentDone" },
                  ]}
                />
              </div>
            </div>
            <div>
              {!hideCallMsgBtn ? (
                <div className="d-flex justify-content-end mt-3">
                  <div>
                    {hasAccess("HAS_LM_SEND_MESSAGES") && (
                      <div className="lead-details-header d-flex align-items-center">
                        <button
                          className="mobile-no-btn  text-blue fw-bold mt-2"
                          // onClick={toggleModal} // Open modal on click
                          onClick={() => {
                            setShowModal(true);
                            setSaveAlternateNumber(false);
                          }} // Open modal on click
                        >
                          <i className="bi bi-telephone">
                            {" "}
                            Add Alternate Mobile Number{" "}
                          </i>
                        </button>
                      </div>
                    )}
                    {showModal && (
                      <div
                        className="modal d-block"
                        style={{ background: "rgba(0, 0, 0, 0.5)" }}
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">
                                Add Alternate Mobile Number
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowModal(false)}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Alternate Mobile No"
                                maxLength={10}
                                value={mobileNumber}
                                onChange={handleMobileNumberChange}
                              />
                              {error && (
                                <small className="text-danger errorNessage">
                                  {error}
                                </small>
                              )}
                            </div>
                            <div className="modal-footer">
                              <button
                                className="btn btn-secondary"
                                onClick={() => setShowModal(false)}
                              >
                                Cancel
                              </button>
                              {mobileNumber < 10 ? (
                                <button
                                  className="btn btn-primary"
                                  disabled
                                  onClick={() => {
                                    handleSaveAlternateNumber();
                                  }}
                                >
                                  Save
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    handleSaveAlternateNumber();
                                  }}
                                >
                                  Save
                                </button>
                              )}
                              {/* <button
                                className="btn btn-primary"
                                onClick={() => {
                                  handleSaveAlternateNumber();
                                }}
                              >
                                Save
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* {hasAccess("HAS_LM_SEND_MESSAGES") && (
                    <div className="lead-details-header d-flex align-items-center">
                      {planDetails && (
                        <CustomButton
                          className="send-massage-btn bg-yellow text-blue fw-bold me-2"
                          onClick={() => setIsSelectMassageType(true)}
                        >
                          <i className="bi-card-text me-2" />
                          Send Message
                        </CustomButton>
                      )}
                    </div>
                  )} */}
                  {hasAccess("HAS_LM_INITITATE_CALL") && (
                    <div className="lead-details-header d-flex align-items-center m-2">
                      <CustomSelect
                        placeholder="Select Disposition Type"
                        style={{ height: "45px" }}
                        options={DISPOSITION_VIA_OPTION}
                        isClearable={true}
                        onChange={(value) => {
                          setIsInitiateCallButtonShow(value?.value || null);
                        }}
                      />
                    </div>
                  )}
                  {/* {isInitiateCallButtonShow &&
                    isInitiateCallButtonShow !== "Without Initiate Call" &&
                    hasAccess("HAS_LM_INITITATE_CALL") && (
                      <div className="d-flex ">
                        <div className="lead-details-header d-flex align-items-center">
                          <CustomButton
                            // type="button"
                            style={{ height: "40px", Padding: "8px" }}
                            className="initiate-call-btn bg-yellow text-blue fw-bold"
                            onClick={handleCallModalToggle}
                            // onClick={() => setShowCallDetails(true)}
                          >
                            <i className="bi bi-telephone me-2" />
                            {isCallInitiated ? "calling..." : "Initiate a Call"}
                          </CustomButton>
                        </div>
                      </div>
                    )} */}
                  {isInitiateCallButtonShow &&
                  isInitiateCallButtonShow !== "Without Initiate Call"
                    ? hasAccess("HAS_LM_INITITATE_CALL") && (
                        <div className="d-flex ">
                          <div className="lead-details-header d-flex align-items-center">
                            <CustomButton
                              // type="button"
                              style={{ height: "40px", Padding: "8px" }}
                              className="initiate-call-btn bg-yellow text-blue fw-bold"
                              onClick={handleCallModalToggle}
                              // onClick={() => setShowCallDetails(true)}
                            >
                              <i className="bi bi-telephone me-2" />
                              {isCallInitiated
                                ? "calling..."
                                : "Initiate a Call"}
                            </CustomButton>
                          </div>
                        </div>
                      )
                    : isInitiateCallButtonShow === "Without Initiate Call" && (
                        <div className="lead-details-header d-flex align-items-center m-1">
                          <CustomSelect
                            placeholder="Select Interaction Type"
                            style={{ height: "45px" }}
                            options={inetactionTypeOption}
                            onChange={(value) => {
                              // setIsInitiateCallButtonShow(value?.value);
                              setInteractionType(value?.value);
                              console.log("value--->", value?.value);
                            }}
                          />
                        </div>
                      )}
                  {/* {
                    <div className="lead-details-header d-flex align-items-center m-2">
                      <CustomSelect
                        placeholder="select Interaction Type"
                        style={{ height: "45px" }}
                        options={inetactionTypeOption}
                        onChange={(value) => {
                          // setIsInitiateCallButtonShow(value?.value);
                        }}
                      />
                    </div>
                  } */}

                  {isCallModalVisible && (
                    <CallSelectionModal
                      show={isCallModalVisible}
                      onClose={handleCallModalToggle}
                      onSelectNumber={(number) => {
                        setSelectedMobileNumber(number);
                        handleInitiateCall();
                        setIsCallModalVisible(false);
                      }}
                      clickOnCall={() => setIscallInitiated(true)}
                      CallID={(CallID) => {
                        // setIscallInitiated();
                        setCallId(CallID || null);
                      }}
                    />
                  )}
                </div>
              ) : null}
              {hasAccess("HAS_LM_DISPOSITION") && (
                <div className="col-md-12 mt-3">
                  <div className="call-details mb-3 d-flex gap-3 p-3">
                    <div className="">Call Details</div>
                    {/* <div>efdfdfdfd</div> */}
                    {/* <CustomSelect
                      options={"selectTellecaller"}
                      placeholder="Select Disposition Via"
                    /> */}
                  </div>
                  <div className="card bg-body-seconary">
                    <div className="card-body">
                      <div className="call-disposition">
                        <CallDetailsComponent
                          interactionType={interactionType}
                          handleSaveClick={() => {
                            // setShowCallDetails(false);
                            // setShowCallDetails(true);
                            setIscallInitiated(false);
                            // setCallId(null);
                          }}
                          getShowCallDetails={() => {
                            setShowCallDetails(true);
                            setCallId(null);
                          }}
                          // dispositionSaveClick={() => funcForHistory()}
                          dispositionSaveClick={() => setHandleSave(true)}
                          statusUpdate={() => getLeadStatus()}
                          callId={callId}
                          isInitiateCallButtonShow={interactionType}
                          // isInitiateCallButtonShow={isInitiateCallButtonShow}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12 mt-3">
                <div className="card bg-body-seconary">
                  <div className="card-body ">
                    <LeadManagmentCallStatusComponent
                      showCallDetails={showCallDetails}
                      handleSaveClick={handleSaveClick}
                      funcForHistory={handleSave}
                      disableFuncForHiatory={() =>
                        setDisableFuncForHistory(false)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        show={isSelectMassageType}
        title="Select Message Type"
        handleClose={() => setIsSelectMassageType(false)}
        handleSave={() => setIsSelectMassageType(false)}
      >
        <SelectMassageTypeComponent
          onCancel={() => setIsSelectMassageType(false)}
          onSave={() => setIsSelectMassageType(false)}
          leadMobNo={leadMobNo}
          leadPolicyNo={leadPolicyNo}
          dataForSMSMail={dataForSMSMail}
          linkForSMS={linkForSMS}
          planDetails={planDetails}
        />
      </CustomModal>
      <CustomModal
        style={{
          width: 400,
          Height: 100,
        }}
        show={isAddTaskListModal}
        title="Add Lead"
        handleClose={() => {
          setIsAddTaskListModal(false);
        }}
      >
        <AddLeadComponent
          onCancel={() => setIsAddTaskListModal(false)}
          onSave={() => {
            setIsAddTaskListModal(false);
            navigate(ROUTES_NAVIGATION.VEHICLE_INSURENCE_ALL_LEADS);
          }}
        />
      </CustomModal>
      <CustomModal
        show={isEditLead}
        title="Edit Lead Information"
        handleClose={() => setIsEditLead(false)}
      >
        <EditLeadInformation
          handleDisacart={() => setIsEditLead(false)}
          handleSave={() => setIsEditLead(false)}
        />
      </CustomModal>
    </MainCard>
  );
};

export default LeadManagementComponent;
