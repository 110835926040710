import React, { useCallback, useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./PolicyDetailsComponent.scss";
import CustomTab from "../../../../shared/CustomTab";
import {
  APIDownloadPreviousYearPolicy,
  APIgetICList,
  APIgetLeadDetailForAdmin,
  APIgetLeadDetails,
  APIgetProposalDetailForAdmin,
  APIgetQuotationDetailForAdmin,
  APIgetQuotFromGiib,
  APIgetSoldPolicyDetailForAdmin,
  APIsendQuoteDataToBE,
} from "../../../../../api/common";
import GlobalContext from "../../../../../context/GlobalContext";
import { useAsyncError, useNavigate, useSearchParams } from "react-router-dom";
import CustomModal from "../../../../shared/CustomModal";
import OwnershipAndClaim from "./ownerShipAndClaim";
import { useDispatch } from "react-redux";
import { displayToast } from "../../../../shared/toast/toastUtils";
import { useLoader } from "../../../../../context/LoaderProvider";
import CustomButton from "../../../../shared/CustomButton";
import moment from "moment";
import { size } from "lodash";
import { ShimmerButton, ShimmerTable } from "react-shimmer-effects";

const TAB_LIST = [
  { id: "details", label: "Details", icon: "bi-card-text" },
  { id: "recommendedplans", label: "Recommended Plans", icon: "bi-card-text" },
];
const PolicyDetailsComponent = ({
  leadName = () => {},
  icName = () => {},
  vehRegNumber = () => {},
  leadDispositionForTel,
  onSendNumber = () => {},
  onSendPolicyNo = () => {},
  handleSaveAlternateNumber,
  saveAlternateNumber,
  onclickSetLink = () => {},
  onSavePalnsDetails,
  isMessageSend = () => {},
}) => {
  const [activeTab, setActiveTab] = useState(TAB_LIST[0]);
  const [previousTab, setPreviousTab] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(["Paid Driver Cover"]);
  const [quotationData, setQuotationData] = useState([]);
  const [leadMainData, setLeadMainData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [detailsForAdmin, setDetailsForAdmin] = useState([]);
  const [optionValue1, setOptionValue1] = useState("");
  const [optionValue2, setOptionValue2] = useState("");
  const [policyNumber, setPolicyNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [icList, setICList] = useState([]);
  const [icListUpdated, setICListUpdated] = useState([]);
  const { setShowLoader } = useLoader();
  const leadId = searchParams.get("leadId");
  const productId = searchParams.get("productId");
  const baseParams = searchParams.get("details");
  const { hasAccess, followUpRefreshFun } = useContext(GlobalContext);
  const role = localStorage.getItem("role");
  const [tableLoader, setTableLoader] = useState(false);
  const [policyData, setPolicyData] = useState();
  const [premiumLink, setPremiumLink] = useState(null);
  const [icPremium, setIcPremium] = useState([]);
  const [deirectionLink, setDirectionLink] = useState();

  const [recommendedPlanDetails, setRecommendedPlanDetails] = useState([]);
  // console.log("premiumArray", premiumArray);

  // console.log("premiumLink", premiumLink);
  console.log("followUpRefreshFun", followUpRefreshFun());

  // const icIdArray = ((icList?.length > 0 && icList) || []).map((val) => {
  //   console.log("####", val?.ic_id);
  //   return val;
  // });

  // console.log("icIdArray", icIdArray);

  const ICLink = quotationData?.map((val) => val?.redirection_url);

  const getAPILeadDetailsHandeler = useCallback(
    async (id) => {
      setShowLoader(true);
      const resp = await APIgetLeadDetails(id);
      setShowLoader(false);
      if (resp?.ok) {
        setLeadMainData(resp?.data?.data?.mainData);
      }
    },
    [setShowLoader]
  );

  const getLeadDetailsForAddmin = async (leadId) => {
    setShowLoader(true);
    const respone = await APIgetLeadDetailForAdmin(leadId);
    setShowLoader(false);
    if (respone?.ok) {
      setDetailsForAdmin(respone?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  // ------------downloadPrevPolicy-------

  const getPrevPolicy = async () => {
    const payload = {
      lead_id: leadId,
      policy_no: policyNumber,
    };
    setShowLoader(true);
    const res = await APIDownloadPreviousYearPolicy(payload);
    setShowLoader(false);

    if (res?.ok) {
      // const policyLink = res?.data?.data?.previous_policy_pdf;
      window.open(res?.data?.data?.previous_policy_pdf, "_blank");
      setTimeout(() => {
        window.URL.revokeObjectURL(res?.data?.data?.previous_policy_pdf);
      }, 100);
      displayToast({
        type: "success",
        title: res?.data?.message,
      });
    } else {
      displayToast({
        type: "error",
        title: res?.data?.message || "Something went wrong",
      });
    }
  };

  // Quotation details send
  const getQuotationDetailsForAddminHandler = async (leadId) => {
    setShowLoader(true);
    const respone = await APIgetQuotationDetailForAdmin(leadId);
    setShowLoader(false);

    if (respone?.ok) {
      setDetailsForAdmin(respone?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };
  // Proposal details send
  const getProposalDetailsForAddminhandler = async (leadId) => {
    setShowLoader(true);
    const response = await APIgetProposalDetailForAdmin(leadId);
    setShowLoader(false);

    if (response?.ok) {
      setDetailsForAdmin(response?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  const getSoldPolicyDetailsForAddminhandler = async (leadId) => {
    setShowLoader(true);
    const response = await APIgetSoldPolicyDetailForAdmin(leadId);
    setShowLoader(false);
    if (response?.ok) {
      setDetailsForAdmin(response?.data?.data);
    } else {
      setDetailsForAdmin([]);
    }
  };

  // MOdel box open on recommended Plan

  const handleModelBox = () => {
    setIsModelOpen(true);
  };

  const getICList = useCallback(async () => {
    const payload = {
      policy_no: policyNumber,
    };
    setShowLoader(true);
    const res = await APIgetICList(payload);
    setShowLoader(false);

    console.log("resList", res?.data?.data);
    if (res?.ok) {
      setICList(res?.data?.data);
      setRecommendedPlanDetails(
        res?.data?.data?.map((data) => ({ ...data, gross_premium: 0 }))
      );
    } else {
      setICList([]);
    }
  }, [policyNumber, setShowLoader]);

  const handleChangeOption = (selectedOption1, selectedOption2) => {
    // setOptionValue1(selectedOption1);
    setOptionValue1(selectedOption1);
    // setOptionValue2(selectedOption2);
    setOptionValue2(selectedOption2);
  };

  const premiumValuePassToBackEnd = async (value) => {
    console.log("value===>", value);
    const payload = {
      ic_id: value?.ic_id,
      ic_code: value?.ic_code,
      ic_logo: value?.ic_logo,
      gross_premium: value?.gross_premium,
      redirection_url: value?.redirection_url,
      quote_no: value?.quote_no,
      quote_id: value?.quote_id,
    };

    console.log("payload<><><>", payload);
    const res = await APIsendQuoteDataToBE(payload);
    console.log("---->", res);
  };

  // ---------without promise---
  const getQuotationHandler = useCallback(
    async (icIdArray, optionValue1, optionValue2) => {
      if (!icIdArray || icIdArray.length === 0) {
        console.warn("icIdArray is empty. No API calls will be made.");
        return;
      }
      console.log("icIdArray", icIdArray);
      const icPremium = [];

      const promise = icIdArray?.map((value) => {
        const payload = {
          policyNumber: policyNumber,
          is_change_in_ownership: optionValue1,
          is_made_claim: optionValue2,
          ic_id: value?.ic_id, // Pass one value at a time
        };
        return { id: value?.ic_id, promiseData: APIgetQuotFromGiib(payload) };

        // const promise = icIdArray?.map(async (value) => {
        //   const payload = {
        //     policyNumber: policyNumber,
        //     is_change_in_ownership: optionValue1,
        //     is_made_claim: optionValue2,
        //     ic_id: value?.ic_id, // Pass one value at a time
        //   };

        //   // Show loader before the API call
        //   setTableLoaderShow(true);

        //   try {
        //     // Await the API response
        //     const promiseData = await APIgetQuotFromGiib(payload);

        //     // Hide loader after API success
        //     setTableLoaderShow(false);

        //     return { id: value?.ic_id, promiseData };
        //   } catch (error) {
        //     // Handle error and hide loader
        //     console.error("Error in API call:", error);
        //     setTableLoaderShow(false);

        //     throw error; // Rethrow error if needed
        //   }
        // });
      });

      const getRespFromPromise = (promiseList) => {
        if (promiseList?.length > 0) {
          Promise.race(promiseList?.map((value) => value?.promiseData))
            .then((resp) => {
              if (resp?.status) {
                const newPromise = promiseList?.filter(
                  (value) => value?.id !== resp?.data?.data?.ic_id
                );

                console.log("newPromise", newPromise);

                // icPremium.push({ ...resp?.data?.data });
                console.log("prev resp", resp?.data?.data);
                setICListUpdated((prev) => {
                  console.log("@@@@@", prev);
                  const newp = prev?.map((d) => {
                    if (d?.ic_id === resp?.data?.data?.ic_id) {
                      return { ...resp?.data?.data, ...d };
                    } else return d;
                  });

                  // setICListUpdated(newp);
                  console.log("prev------>>>>", newp, resp?.data?.data);
                  return newp;
                });

                getRespFromPromise(newPromise);
              }
            })
            .catch((error) => {
              // const newPromise = promiseList?.filter(
              //   (value) => value?.id !== error?.data?.data?.ic_id
              // );
              // getRespFromPromise(newPromise);
            })
            .finally(() => {
              console.log("icPremium-->>>", icPremium);
            });

          // ---------test-----
          // setPolicyData((prev) => [...prev, resp?.data?.data]);
          // ----------test--------
        } else {
          // console.error("Error response for ic_id", icId, ":", resp);
          // Handle failure (e.g., display error toast)
          // setPolicyData({});
          // -------------test---------
          // resultsArray.push({
          //   ic_id: icId,
          //   error: resp?.data?.message || "Error occurred",
          // });
          // -------test--------------
          // setPolicyData((prev) => console.log("prev-->", prev));
          // setPolicyData((prev) => [...prev, {}]);
        }
        // } catch (error) {
        //   console.error("Error while calling API for ic_id", icId, ":", error);
        // }
      };

      getRespFromPromise(promise);
      console.log("icPremium", icPremium);
    },
    [policyNumber]
  );

  // -----------redirectio function-----------
  const redirectionFun = (arr) => {
    console.log("arr===>", arr?.redirection_url);
    const onClickDirectionLink = (url) => {
      // if (arr?.redirection_url) {
      //   window.location.href = arr.redirection_url; // Redirect to the URL
      //   return window.location.href;
      // } else {
      //   console.error("Redirection URL is not available");
      // }
      if (url) {
        // window.location.href = url; // Redirect to the URL
        // return window.location.href;
      } else {
        console.error("Redirection URL is not available");
      }
    };
  };
  const onClickDirectionLink = (url) => {
    if (url) {
      // window.location.href = url;
      window.open(url, "_blank");
      return window.open(url, "_blank");
    } else {
      console.error("Redirection URL is not available");
    }
  };

  useEffect(() => {
    if (activeTab?.id === "recommendedplans") {
      handleModelBox();
    }
  }, [activeTab?.id, getICList]);

  const handleCheckboxChange = (label) => {
    setSelectedOptions((prev) =>
      prev.includes(label)
        ? prev.filter((item) => item !== label)
        : [...prev, label]
    );
  };

  useEffect(() => {
    if (leadId) {
      getAPILeadDetailsHandeler(leadId);
    }
  }, [leadId, getAPILeadDetailsHandeler]);

  useEffect(() => {
    if (baseParams === "leadDetails" || saveAlternateNumber) {
      getLeadDetailsForAddmin(leadId);
    }
  }, [baseParams, leadId, saveAlternateNumber]);

  useEffect(() => {
    if (baseParams === "quotation") {
      getQuotationDetailsForAddminHandler(leadId);
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "praposal") {
      getProposalDetailsForAddminhandler(leadId);
    }
  }, [baseParams, leadId]);

  useEffect(() => {
    if (baseParams === "sold") {
      getSoldPolicyDetailsForAddminhandler(leadId);
    }
  }, [baseParams, leadId]);

  const PolicyNumber = detailsForAdmin.map(
    (value) => value?.fields.map((val) => val?.value)[0]
  )[0];

  const mobNo = detailsForAdmin.map(
    (val) => val?.fields.map((value) => value?.value)[3]
  )[3];

  // const vehRegnumber =

  const regNumber = detailsForAdmin.map(
    (value) => value?.fields.map((val) => val?.value)[1]
  )[0];

  // const policyEndDate =

  // const handleRedirection = () => {
  //   setDirectionLink();
  // };

  useEffect(() => {
    setPolicyNumber(PolicyNumber);
    setMobileNumber(mobNo);
    vehRegNumber(regNumber);
  }, [PolicyNumber, mobNo, regNumber, vehRegNumber]);
  useEffect(() => {
    onSendNumber(mobileNumber);
    onSendPolicyNo(policyNumber);
  }, [mobileNumber, onSendNumber, onSendPolicyNo, policyNumber]);

  // ------------------not sure------------
  useEffect(() => {
    if (icList && icList.length > 0 && optionValue1 && optionValue2) {
      getQuotationHandler(icList, optionValue1, optionValue2);
      setICListUpdated(icList);
    }
  }, [getQuotationHandler, icList, optionValue1, optionValue2]);
  // --------------------not sure-------------

  return (
    <div>
      <div className="card pt-3">
        {hasAccess("HAS_LM_VIEW_LEADS_DETAILS_QUOTATION") && (
          <div>
            <div className=" d-flex justify-content-between px-2">
              <div className="abc">
                <CustomTab
                  tabList={TAB_LIST}
                  onClick={(tab) => {
                    setActiveTab(tab);
                  }}
                  style={{ width: "8rem" }}
                  activeTab={activeTab}
                />
              </div>
              <CustomButton
                tooltip={"Download Previous Policy"}
                placement="bottom"
                className="px-4"
                onClick={() => getPrevPolicy()}
              >
                <i className="bi bi-download"></i>
              </CustomButton>
            </div>

            <div className="card-body policydetails-Main-div">
              <div>
                {activeTab?.id === "details" && (
                  <Accordion defaultActiveKey="0" flush>
                    {/* {POLICY_DETAILS?.map((value, index) => { */}
                    {/* {leadDispositionForTel?.map((value, index) => { */}
                    {detailsForAdmin?.map((section, index) => {
                      // console.log()
                      return (
                        <Accordion.Item
                          eventKey={`${index}-${section?.title}`}
                          className="custom-accordion"
                        >
                          <Accordion.Header className="custom-accordion-header">
                            {section?.title}
                          </Accordion.Header>
                          <Accordion.Body className="custom-accordion-body ">
                            <div className="policydetails">
                              {section?.fields?.length === 0 ? (
                                <div>No Result Found</div>
                              ) : (
                                section?.fields?.map((data) => {
                                  return (
                                    <div className="policydetails-text-main-container">
                                      <div className="policydetails-text-container">
                                        <div className="policydetails-text-rightside">
                                          {data?.label}
                                        </div>
                                        <div className="policydetails-text-leftside">
                                          {data?.value}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                )}
                {console.log(
                  "icList___________------->>>",
                  icList,
                  icListUpdated
                )}
                {activeTab?.id === "recommendedplans" &&
                  // quotationData.map((value) => (
                  ((icListUpdated?.length > 0 && icListUpdated) || [])?.map(
                    (value) => (
                      <div className="recommended-plan border-end">
                        {/* -----------test---------- */}
                        {/* {redirectionFun(value)} */}
                        <div
                          className="card p-3 mb-3 IC1"
                          // onClick={() => setIsModelOpen(true)}
                          // onClick={() => {
                          //   onClickDirectionLink(value?.redirection_url);
                          //   setPremiumLink(value?.redirection_url);
                          //   onclickSetLink(value?.redirection_url);
                          //   onSavePalnsDetails(value);
                          // }}
                        >
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="d-flex ">
                              <img
                                // src="https://dev.mypolicynow.com/assets/front/img/partners-logos/hdfc-logo.png"
                                src={value?.ic_logo}
                                alt="TOICL Logo"
                                style={{ width: "50px" }}
                              />
                              <div className="ms-3">
                                <h5 className="mb-0 title">{value?.ic_name}</h5>
                                {/* {
                                  <h5 className="mb-0 title">
                                    Premium <strong>₹</strong>
                                    {value?.gross_premium ? (
                                      <strong>{value?.gross_premium}</strong>
                                    ) : (
                                      <ShimmerButton size="sm" />
                                    )}
                                  </h5>
                                } */}
                                {value?.gross_premium ? (
                                  <>
                                    Premium{" "}
                                    <strong>₹ {value?.gross_premium}</strong>
                                  </>
                                ) : value?.gross_premium === null ? (
                                  <div className="premium-na" role="status">
                                    {/* N/A */}
                                    <>
                                      Premium <strong>₹ N/A</strong>
                                    </>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <div>
                                      Premium <strong>₹</strong>
                                    </div>
                                    <div>
                                      <ShimmerButton size="sm" />
                                    </div>
                                  </div>
                                )}

                                {/* -------- */}
                                {/* <h5 className="mb-0 title">
                                  Premium{" "}
                                  <strong>₹{value?.gross_premium}</strong>
                                </h5> */}
                              </div>
                            </div>
                            {/* {
                            policyData&&(<div>
                              <h4 className="price-tag text-primary mb-0">
                                ₹{policyData?.gross_premium}
                              </h4>
                            </div>)
                          } */}
                            <div className="premium-render-link">
                              {/* <h5 className="price-tag text-primary mb-0">
                                {value?.gross_premium ? (
                                  `₹${value?.gross_premium}`
                                ) : value?.gross_premium === null ? (
                                  <div className="premium-na" role="status">
                                    N/A
                                  </div>
                                ) : (
                                  <div
                                    className="spinner-grow spinner-grow-md"
                                    role="status"
                                  ></div>
                                )}
                              </h5> */}
                              <div className="pre-btn-group">
                                <CustomButton
                                  // className="report-download-btn mr-2 text-center d-flex align-items-center justify-content-center"
                                  color=" #4285F4"
                                  textColor="white"
                                  style={{ fontSize: "12px" }}
                                  onClick={() => {
                                    console.log("***", value);
                                    onClickDirectionLink(
                                      value?.redirection_url
                                    );
                                    if (value?.redirection_url) {
                                      premiumValuePassToBackEnd(value);
                                    }
                                  }}
                                >
                                  Buy Now
                                </CustomButton>
                                <CustomButton
                                  color="var(--lgi-gray)"
                                  textColor=" #4285F4"
                                  className="px-4"
                                  style={{
                                    border: "2px solid #4285F4",
                                    fontSize: "12px",
                                  }}
                                  onClick={() => {
                                    onSavePalnsDetails(value);
                                    // setIsModelOpen(true);
                                    // isMessageSend();
                                    if (value?.quote_id) {
                                      isMessageSend();
                                      // premiumValuePassToBackEnd(value);
                                    }
                                  }}
                                >
                                  Share
                                </CustomButton>
                              </div>
                            </div>
                          </div>
                          {/* <div className="mb-3 mt-3">
                        {(value?.coverList).map((option) => (
                          <div className="form-check" key={option.Id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={option.Id}
                              checked={selectedOptions.includes(option)}
                              onChange={() => handleCheckboxChange(option)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={option}
                            >
                              {option?.label}
                            </label>
                          </div>
                        ))}
                      </div> */}
                          {/* <button
                        className="btn insurance-portal-btn w-100"
                        onClick={() =>
                          (window.location.href = value?.buttonData?.link)
                        }
                      >
                        {value?.buttonData?.title}
                      </button> */}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        )}
        {hasAccess("HAS_UAR_USERS_VIEW_DETAILS") && (
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex mt-2 headline-details">
                <i className="bi bi-card-text" />
                <div className="ms-2 mb-2">Details</div>
                {/* <span className="header-according-action">
                <CustomButton
                  tooltip={"Edit"}
                  className={"rounded-btn text-dark-teal bg-gray p-0"}
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleToggle();
                    // setOpenInsuForm(true);
                  }}
                >
                  <Icon path={mdiPencilOutline} size={1} />
                </CustomButton>
              </span> */}
              </div>
              <div className="px-2">
                <CustomButton
                  tooltip={"Download Previous Policy"}
                  placement="bottom"
                  className="px-4"
                  onClick={() => getPrevPolicy()}
                >
                  <i className="bi bi-download"></i>
                </CustomButton>
              </div>
            </div>
            <hr />
            <div className="card-body policydetails-Main-div">
              <Accordion defaultActiveKey="0" flush>
                {/* {leadMainData?.map((value, index) => { */}

                {detailsForAdmin.length === 0 ? (
                  <div>No Record Found</div>
                ) : (
                  detailsForAdmin?.map((value, index) => {
                    return (
                      <Accordion.Item
                        eventKey={index}
                        className="custom-accordion "
                      >
                        <Accordion.Header className="custom-accordion-header">
                          {value?.title}
                        </Accordion.Header>
                        <Accordion.Body className="custom-accordion-body ">
                          <div className="policydetails">
                            {value?.fields?.length === 0 ? (
                              <div>No Result Found</div>
                            ) : (
                              value?.fields?.map((data) => {
                                return (
                                  <div className="policydetails-text-main-container">
                                    <div className="policydetails-text-container">
                                      <div className="policydetails-text-rightside">
                                        {data?.label}
                                      </div>
                                      <div className="policydetails-text-leftside">
                                        {data?.value}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                )}
              </Accordion>
            </div>
          </div>
        )}
      </div>
      <CustomModal
        show={isModelOpen}
        title="Ownership and Claim Details"
        handleClose={() => {
          setIsModelOpen(false);
          setActiveTab(TAB_LIST[0]);
        }}
      >
        <OwnershipAndClaim
          onSave={() => {
            setIsModelOpen(false);
            getICList();
          }}
          optionChange={handleChangeOption}
          onCancel={() => {
            setIsModelOpen(false);
            setActiveTab(TAB_LIST[0]);
          }}
        />
      </CustomModal>

      {/* --------------Accordian----------- */}
    </div>
  );
};

export default PolicyDetailsComponent;
