import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../../shared/CustomButton";
import "./LeadManagement.scss";
import "./SelectMessagetype.scss";
import { useForm, Controller } from "react-hook-form";

import CustomInput from "../../../shared/CustomInput";
import { useSearchParams } from "react-router-dom";
import { APISendEmail, APISendSMS } from "../../../../api/common";
import { displayToast } from "../../../shared/toast/toastUtils";
const SelectMassageTypeComponent = ({
  onCancel = () => {},
  onSave = () => {},
  leadMobNo,
  leadPolicyNo,
  dataForSMSMail,
  linkForSMS,
  planDetails,
}) => {
  const [messageType, setMessageType] = useState("renewal");
  const [smsChecked, setSmsChecked] = useState(false);
  // const [whatsappChecked, setWhatsappChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(false);
  const [mobile1, setMobile1] = useState(null);
  const [mobile2, setMobile2] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { control } = useForm({});

  const [searchParams] = useSearchParams();

  console.log("planDetails", planDetails);
  console.log("dataForSMSMail", dataForSMSMail);

  // const endState = dataForSMSMail?.policyEndDate;

  const [year, month, day] = dataForSMSMail?.policyEndDate?.split("-");
  const endDate = `${day}-${month}-${year}`; // "22-12-2025"

  const leadId = searchParams.get("leadId");
  const productId = searchParams.get("productId");

  const handleSubmit = useCallback(async () => {
    if (smsChecked) {
      const payload = {
        // mobile_no: leadMobNo,
        mobile_no: mobile2,
        policy_number: leadPolicyNo,
      };

      const resp = await APISendSMS(payload);

      if (resp?.ok) {
        onSave();
        displayToast({
          type: "success",
          title: resp?.data?.message,
        });
      } else {
        displayToast({
          type: "error",
          title: resp?.data?.message,
        });
      }
    }
    if (emailChecked) {
      const payload = {
        // email: email,
        lead_id: dataForSMSMail?.leadId,
        quote_id: planDetails?.quote_id,
      };
      const resp = await APISendEmail(payload, leadId);

      if (resp?.ok) {
        onSave();
        displayToast({
          type: "success",
          title: resp?.data?.message,
        });
      } else {
        displayToast({
          type: "error",
          title: resp?.data?.message,
        });
      }
    }
  }, [
    dataForSMSMail?.leadId,
    emailChecked,
    leadId,
    leadPolicyNo,
    mobile2,
    onSave,
    planDetails?.quote_id,
    smsChecked,
  ]);

  // useEffect(() => {
  //   handleSubmit();
  // }, [handleSubmit]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log("value----", value);
    if (/^[6789]\d{0,9}$/.test(value) || value === "") {
      setMobile2(value);
      setError(""); // Clear error if the input is valid
    } else {
      setError(
        "Number must start with 6, 7, 8, or 9 and have up to 10 digits."
      );
    }
  };

  return (
    <div className="container select-massage">
      {/* <div className="form-check form-check-inline mt-2">
        <input
          className="form-check-input"
          type="radio"
          name="messageType"
          id="sendRenewal"
          // value="renewal"
          value={mobile1}
          checked={messageType === "renewal"}
          onChange={() => setMessageType("renewal")}
        />
        <label className="form-check-label" htmlFor="sendRenewal">
          Send Renewal Notice
        </label>
      </div> */}
      {/* <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="messageType"
          id="sendNilEndorsement"
          value="nilEndorsement"
          checked={messageType === "nilEndorsement"}
          onChange={() => setMessageType("nilEndorsement")}
        />
        <label className="form-check-label" htmlFor="sendNilEndorsement">
          Send Nil Endorsement Link
        </label>
      </div> */}

      <div className="row mt-3 middle-content">
        {/* <div className="col-md-6 second-content p-3 me-4 ms-2"> */}
        <div className="col-6 second-content p-3 me-4 ms-2">
          <div className="check-box d-flex justify-content-center align-items-center p-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="smsCheckbox"
              checked={smsChecked}
              onChange={() => setSmsChecked(!smsChecked)}
            />
            <label className="custom-control-label" htmlFor="smsCheckbox">
              SMS
            </label>
          </div>
          {smsChecked && (
            <div>
              <input
                type="text"
                className="form-control my-2"
                placeholder="Mobile 1"
                value={leadMobNo}
                onChange={(e) => setMobile1(e.target.value)}
                disabled
              />
              <input
                type="text"
                // className="form-control my-2"
                className={`form-control my-2 ${error ? "is-invalid" : ""}`}
                placeholder="Enter Alternate Mobile Number"
                value={mobile2}
                onChange={(e) => handleInputChange(e)}
                // onChange={(e) => {
                //   const value = e.target.value;
                //   if (/^[678]\d{0,9}$/.test(value) || value === "") {
                //     setMobile2(value);
                //   }
                // }}
              />
              {error && (
                <small className="text-danger errorNessage">{error}</small>
              )}
            </div>
          )}
        </div>

        {/* <div className="col-md-4 second-content p-3 ">
          <div className="check-box d-flex justify-content-center align-items-center p-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="whatsappCheckbox"
              checked={whatsappChecked}
              onChange={() => setWhatsappChecked(!whatsappChecked)}
            />
            <label className="custom-control-label" htmlFor="whatsappCheckbox">
              WhatsApp
            </label>
          </div>
          {whatsappChecked && (
            <div>
              <input
                type="text"
                className="form-control my-2"
                placeholder="Mobile 1"
                value={mobile1}
                onChange={(e) => setMobile1(e.target.value)}
              />
              <input
                type="text"
                className="form-control my-2"
                placeholder="Mobile 2"
                value={mobile2}
                onChange={(e) => {
                  setMobile2(e.target.value);
                  console.log(e.target.target);
                }}
              />
            </div>
          )}
        </div> */}

        <div
          // className="col-md-6 second-content p-3 me-2 ms-4"
          className="col-6 second-content p-3 me-2 ms-4"
          style={{
            border: "1px solid rgba(220, 211, 238, 1)",
            borderRadius: "10px",
            width: "230px",
          }}
        >
          <div className="check-box d-flex justify-content-center align-items-center p-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="emailCheckbox"
              checked={emailChecked}
              onChange={() => setEmailChecked(!emailChecked)}
            />
            <label className="custom-control-label" htmlFor="emailCheckbox">
              Email
            </label>
          </div>
          {emailChecked && (
            <div>
              {/* <input
                type="email"
                className="form-control my-2"
                placeholder="Email Address"
              /> */}
              <div className="col-sm-12" style={{ padding: "5px 5px 15px" }}>
                <Controller
                  control={control}
                  name={"leadEmail"}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required to be filled*",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org|co|net)$/,
                      message: "Invalid Email Id format",
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <div className="custom-input-wrapper">
                      <label htmlFor="customInput" className="form-label">
                        {"Email "}
                      </label>
                      <span className="text-danger">*</span>
                      <div className="input-with-prefix">
                        <input
                          type={"text"}
                          className="form-control form-control-lg"
                          placeholder={"Enter Alternate Email"}
                          value={value}
                          onChange={(e) => {
                            // handleFormUpdate(value);
                            onChange(e);
                            setEmail(e.target.value);
                          }}
                          onBlur={onBlur}
                        />
                      </div>

                      {error && (
                        <div className="error-message-input">
                          {error.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-3">
        {emailChecked && (
          <div>
            <p className="email-content">
              Dear Mr/Mrs. {dataForSMSMail?.leadname} ,
              <br />
              Welcome to Global-India Insurance Brokers !!!
              <br />
              Please find attached Quotation details which is generated on the
              basis of inputs provided by you on our web portal.
              <br />
              You may print a copy of the same at your end and keep it for your
              reference.
              <br />
              Confirm on Quotation to below dealers to proceed with Proposal and
              Policy.
              <br />
              Policy Details:
              <br />
              Policy No.: <strong>{dataForSMSMail?.policyNo}</strong>
              <br />
              Vehicle Registration No.:{" "}
              <strong>{dataForSMSMail?.registrationNo}</strong>
              <br />
              Policy End Date: <strong>{endDate}</strong>
              <br />
              Dealer Details:
              <br />
              Dealer name : {dataForSMSMail?.Dealer_name}
              <br />
              Dealer Email : {dataForSMSMail?.Dealer_Email}
              <br />
              Dealer Mob: {dataForSMSMail?.Dealer_Mob}
              <br />
              <a
                href={planDetails?.redirection_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to view Quote Details.
              </a>
              <br />
              For any further assistance please contact us at{" "}
              <a
                href="https://www.giib.co.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                renewal@giib.co.in
              </a>{" "}
              or our toll free
              <br />
              <strong>022-4002-1935</strong>.
            </p>
            <p>
              Warm regards <br />
              <strong>Global-India Insurance Brokers</strong>
            </p>
          </div>
        )}
        {smsChecked && (
          <div>
            {" "}
            <p>
              Dear Mr.{dataForSMSMail?.leadname} , Welcome to Global-India
              Insurance Brokers !!!. The renewal of your Motor policy No:
              <strong>{dataForSMSMail?.policyNo}</strong> for vehicle No:{" "}
              <strong>{dataForSMSMail?.registrationNo} </strong>is due for
              renewal on <strong>{endDate}</strong> for renewal of policy.
              <br />
              <a
                href={planDetails?.redirection_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to view Quote Details.
              </a>
            </p>
            <p>
              Warm regards <br />
              <strong>Global-India Insurance Brokers</strong>
            </p>
          </div>
        )}
      </div>
      <div className="form-button-main d-flex justify-content-end mt-3">
        <CustomButton
          className="view-template-btn mr-2 me-2 text-center d-flex align-items-center justify-content-center"
          onClick={onCancel}
        >
          <span className="view-btn ">Cancel</span>
        </CustomButton>
        <CustomButton className="report-download-btn mr-2 text-center d-flex align-items-center justify-content-center">
          <span className="download-btn " onClick={handleSubmit}>
            Send
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

export default SelectMassageTypeComponent;
