// import React from "react";
// import "./CustomModal.scss";
// import CustomButton from "../CustomButton";

// const CustomModal = ({
//   show,
//   handleClose,
//   handleSave,
//   title,
//   body,
//   children,
//   buttonsave,
//   buttonclose,
//   notify,
// }) => {
//   if (!show) return null;
//   return (
//     <div className="custom-modal" style={notify}>
//       <div className="custom-modal-content">
//         <div className="custom-modal-header">
//           <h5 className="fw-bold fs-5">{title}</h5>
//           <button className="custom-modal-close" onClick={handleClose}>
//             &times;
//           </button>
//         </div>
//         <div className="custom-modal-body">
//           {body ? <p>{body}</p> : null}
//           {children}
//         </div>
//         {/* <div className="custom-modal-footer">
//           <button className="btn footer-closed" onClick={handleClose}>
//             {buttonclose || "Close"}
//           </button>
//           <button className="btn footer-save" onClick={handleSave}>
//             {buttonsave || "Save"}
//           </button>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default CustomModal;

import React, { useEffect } from "react";
import "./CustomModal.scss";
import CustomButton from "../CustomButton";
import { Modal } from "react-bootstrap";

const CustomModal = ({
  show = false,
  handleClose = () => {},
  handleSave = () => {},
  title,
  body,
  children,
  buttonsave,
  buttonclose,
  style,
  size = "lg",
  
  
}) => {
  // const lockScroll = React.useCallback(() => {
  //   document.body.style.overflow = "hidden";
  // }, []);

  // const unlockScroll = React.useCallback(() => {
  //   document.body.style.overflow = "scroll";
  // }, []);

  // useEffect(() => {
  //   if (show) {
  //     lockScroll();
  //   } else {
  //     unlockScroll();
  //   }
  //   return () => {
  //     unlockScroll();
  //   };
  // }, [show, lockScroll, unlockScroll]);

  // if (!show) return null;

  return (
    // <div className="custom-modal">
    //   <div className="custom-modal-content" style={style}>
    //     <div className="custom-modal-header">
    //       <h5 className="fw-bold fs-5">{title}</h5>
    //       <button className="custom-modal-close" onClick={handleClose}>
    //         &times;
    //       </button>
    //     </div>
    //     <div className="custom-modal-body">
    //       {body && <p>{body}</p>}
    //       {children}
    //     </div>
    //     <div className="custom-modal-footer">
    //       <button className="btn footer-closed" onClick={handleClose}>
    //         {buttonclose || "Close"}
    //       </button>
    //       <button className="btn footer-save" onClick={handleSave}>
    //         {buttonsave || "Save"}
    //       </button>
    //     </div>
    //   </div>
    // </div>

    <Modal
    //  onHide={handleClose} // This ensures the close button works
      backdrop="static" // Prevents closing when clicking outside
      keyboard={false} // Prevents closing on 'Escape' key press
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      // style={style}
      // {...props}
      
    >
      <Modal.Header closeButton={handleClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="fw-bold fs-5">{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body && <p>{body}</p>}
        {children}
      </Modal.Body>
      {/* <Modal.Footer>
        <button className="btn footer-closed" onClick={handleClose}>
          {buttonclose || "Close"}
        </button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default CustomModal;
