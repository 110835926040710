export const DISPOSITION_VIA_OPTION = [
  { value: "With Initiate Call", label: "With Initiate Call" },
  { value: "Without Initiate Call", label: "Without Initiate Call" },
];

export const DISPOSITION_VIA = [
  { value: "sms", label: "SMS" },
  { value: "email", label: "Email" },
  { value: "whatsApp", label: "Whats App" },
];

export const INTERACTION_HISTORY = [
  {
    mode: "SMS",
    disposition: "Follow Up	",
    cre: "Ritik TC",
    date: "29-01-2025",
    remark: "Product Query",
  },
  {
    mode: "Email",
    disposition: "quote send",
    cre: "Ritik TC",
    date: "30-01-2025",
    remark: "quote send",
  },
];

export const REPORT_TYPE_OPTION = [
  { value: "mis", label: "MIS Report" },
  { value: "misAll", label: "MIS Report with All Data" },
];
