import React, { useEffect, useState } from "react";
import CustomTab from "../../../../shared/CustomTab";
import CustomTable from "../../../../shared/CustomTable";
import { values } from "lodash";

const ExistLeadTableList = ({ tableRowData, onMobileNumberClick }) => {
  const [tableData, setTableDta] = useState([]);
  console.log("object----->", tableData);

  const mobNumberRender = (val) => {
    if (val)
      return (
        <div
          className="cursor-pointer fw-bold"
          style={{ color: "rgba(6, 116, 140, 1)", cursor: "pointer" }}
          onClick={() => {
            onMobileNumberClick(val);
          }}
        >
          {val?.mobile_no}
        </div>
      );
  };
  const TableColumnDataList = [
    {
      id: 6,
      label: "Mobile No.",
      rowId: "mobNumberRender",
      position: "center",
      isRenderItem: true,
    },
    // {
    //   id: 1,
    //   label: "Lead Id",
    //   rowId: "leadId",
    //   position: "center",
    //   isRenderItem: true,
    // },
    {
      id: 2,
      label: "Lead Name",
      rowId: "leadname",
      position: "center",
      isRenderItem: true,
    },

    {
      id: 3,
      label: "Product Type",
      rowId: "product_type",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 4,
      label: "Policy Type",
      rowId: "policy_type",
      position: "center",
      isRenderItem: true,
    },
    {
      id: 5,
      label: "CRE",
      rowId: "cre",
      position: "center",
      isRenderItem: true,
    },
  ];

  useEffect(() => {
    if (tableRowData) {
      setTableDta(
        tableRowData.map((value) => {
          return {
            ...value,
            mobNumberRender: mobNumberRender(value),
          };
        })
      );
    }
  }, [tableRowData]);
  return (
    <div>
      <CustomTable tableColumn={TableColumnDataList} tableRow={tableData} />
    </div>
  );
};

export default ExistLeadTableList;
