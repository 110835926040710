import React, { useState } from "react";
import CustomButton from "../../../../shared/CustomButton";
import { mdiPlus } from "@mdi/js";
import { Icon } from "@mui/material";
import AddLeadComponent from "../../AllLeadsComponent/AddLeadComponent";
import CustomModal from "../../../../shared/CustomModal";

const InboundInfo = ({ mobileNumber }) => {
  const [isAddTaskListModal, setIsAddTaskListModal] = useState(false);
  console.log("qqqqqqq", mobileNumber);
  return (
    <div>
      <p>
        <strong> Receiving Incoming Call from +91 - {mobileNumber}</strong>
        <br />
        Our records indicate that this number is not present in the system.
        <br />
        Click the button to add this as a new lead to the system.
      </p>
      <div className=" d-flex flex-wrap gap-2 justify-content-end">
        <CustomButton
          className={"rounded-2 gap-2 px-3 text-blue qutation-btn bg-teal"}
          style={{ backgroundColor: "teal", color: "white" }}
          onClick={() => {
            setIsAddTaskListModal(true);
          }}
        >
          {/* <Icon path={mdiPlus} size={0.8} /> */}
          <i className="bi bi-plus text-white"></i>
          <div className="text-blue font-8">Add Lead</div>
        </CustomButton>
      </div>

      <CustomModal
        show={isAddTaskListModal}
        title="Add Lead"
        handleClose={() => {
          setIsAddTaskListModal(false);
        }}
      >
        <AddLeadComponent
          mobileNumber={mobileNumber}
          onCancel={() => setIsAddTaskListModal(false)}
          onSave={() => {
            setIsAddTaskListModal(false);
            // getAPITaskListHandler();
            // APILeadCountHandler();
          }}
        />
      </CustomModal>
    </div>
  );
};

export default InboundInfo;
