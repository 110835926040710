import React, { useCallback, useContext, useEffect, useState } from "react";
import CustomDropdown from "../../../../shared/CustomDropdown";
import CustomDateInput from "../../../../shared/CustomDateInput";
import "./CallDetails.scss";
import CustomButton from "../../../../shared/CustomButton";
import {
  addDisposition,
  APIcallDrop,
  APIgetDispositionDropdown,
  APIgetLeadTypeDropDown,
  APIgetSubDispositionDropdown,
} from "../../../../../api/common";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { mdiVanityLight } from "@mdi/js";
import { displayToast } from "../../../../shared/toast/toastUtils";
import GlobalContext from "../../../../../context/GlobalContext";

const CallDetailsComponent = ({
  getShowCallDetails = () => {},
  handleSaveClick = () => {},
  dispositionSaveClick = () => {},
  statusUpdate = () => {},
  callId = null,
  isInitiateCallButtonShow,
  interactionType,
}) => {
  const [dispositoinOption, setDispositionOption] = useState(null);
  const [dispositionID, setDispositionId] = useState(null);
  const [subDispositionOption, setSubDispositionOption] = useState(null);
  const [subDispositionId, setSubDispositionid] = useState(null);
  const [LeadTypeIdOption, setLeadTypeIdOption] = useState();
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("leadId");
  const ProductId = searchParams.get("productId");
  const [isSaving, setIsSaving] = useState(false);
  const [leadType, setLeadType] = useState();
  const today = new Date();
  const Today = today.toISOString().split("T")[0];
  const [successPopup, setSuccessPopup] = useState(false);
  const [remark, setRemark] = useState(null);
  const [nextFollowUpDate, setNextFollowUpDate] = useState(null);
  const [nextFollowUpTime, setNextFollowUpTime] = useState(null);
  const { handleDispositionSubmitted } = useContext(GlobalContext);

  console.log("interactionType<><>", interactionType);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // const [formData, setFormData] = useState({
  //   dispositionId: null,
  //   nextFollowUpDate: null,
  //   nextFollowUpTime: null,
  //   remarks: "",
  //   leadType: null,
  // });

  const resetHandler = () => {
    // setFormData({
    //   dispositionId: {},
    //   nextFollowUpDate: null,
    //   nextFollowUpTime: null,
    //   remarks: "",
    //   leadType: null,
    //   subDispositionId: null,
    // });
    setDispositionId("");
    setSubDispositionid("");
    setLeadType("");
    setNextFollowUpDate(null);
    setNextFollowUpTime(null);
    setRemark("");
  };

  // const onSubmit = useCallback(
  //   async (data) => {
  //     const payload = {
  //       ...data,
  //       nextFollowUpDate: data.nextFollowUpDate
  //         ? moment(data.nextFollowUpDate, "DD/MM/YYYY").format("YYYY-MM-DD")
  //         : null,
  //       nextFollowUpTime: data.nextFollowUpTime
  //         ? moment(data.nextFollowUpTime, "hh:mm A").format("HH:mm")
  //         : null,
  //       leadMasterId: leadId,
  //       leadProductId: ProductId,
  //       leadType: leadType || "",
  //       call_id: callId || "",
  //       // remark: remark,
  //       // subDispositionId: subDispositionId || null,
  //     };
  //     Object.keys(payload).forEach((key) => {
  //       if (!payload[key]) {
  //         payload[key] = null;
  //       }
  //     });

  //     setIsSaving(true);

  //     // const result = await addDisposition(payload);
  //     // if (result?.ok) {
  //     //   handleSaveClick();
  //     //   dispositionSaveClick();
  //     //   resetHandler();
  //     //   statusUpdate();
  //     //   setIsSaving(false);

  //     //   // setSuccessPopup(true);
  //     //   displayToast({
  //     //     type: "success",
  //     //     title: result?.data?.message,
  //     //   });
  //     // } else {
  //     //   displayToast({
  //     //     type: "error",
  //     //     title: result?.data?.message,
  //     //   });
  //     // }
  //   },
  //   [
  //     leadId,
  //     ProductId,
  //     leadType,
  //     callId,
  //     // handleSaveClick,
  //     dispositionSaveClick,
  //     statusUpdate,
  //   ]
  // );

  const getDispositionDropdownOption = useCallback(async () => {
    const resp = await APIgetDispositionDropdown(true);
    if (resp?.ok) {
      setDispositionOption(resp?.data?.data || []);
    } else {
      setDispositionOption([]);
    }
  }, []);

  const getSubDispositionDropDown = useCallback(async (id) => {
    // const payload = {
    //   id: dispositoinOption?.value,
    // };
    const resp = await APIgetSubDispositionDropdown(id);
    setSubDispositionOption(resp?.data?.data);
  }, []);

  const getLeadTypeDropdownOption = useCallback(async () => {
    const payload = {
      status: true,
    };
    const resp = await APIgetLeadTypeDropDown(payload);
    if (resp?.ok) {
      const options = resp?.data?.data.map((item) => ({
        value: Number(item.leadTypeId),
        label: item.name,
      }));
      setLeadTypeIdOption(options || []);
    } else {
      setLeadTypeIdOption([]);
    }
  }, []);

  // const handleFormUpdates = (e) => {
  //   const { name, value } = e.target;
  //   // setDispositionId(value);
  //   setSubDispositionid(value);
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  //   setValue(name, value);
  //   getShowCallDetails(true);
  // };

  // const handleDateChange = (date) => {
  //   const formattedDate = date ? moment(date).format("DD/MM/YYYY") : null;
  //   setFormData((prev) => ({
  //     ...prev,
  //     nextFollowUpDate: formattedDate,
  //   }));
  //   setValue("nextFollowUpDate", formattedDate);
  // };

  // const handleTimeChange = (time) => {
  //   const formattedTime = time ? moment(time).format("hh:mm A") : null;
  //   setFormData((prev) => ({
  //     ...prev,
  //     nextFollowUpTime: formattedTime,
  //   }));
  //   setValue("nextFollowUpTime", formattedTime);
  // };

  const dropCallFun = async () => {
    const payload = {
      leadId: leadId,
    };
    const res = await APIcallDrop(payload);
    if (res?.status) {
      console.log("call droped");
    } else {
      displayToast({
        type: "error",
        title: res?.data?.message,
      });
    }
  };

  const dispositionSave = async () => {
    const payload = {
      call_id: callId || null,
      dispositionId: dispositionID,
      subdispositionId: subDispositionId || null,
      leadMasterId: leadId || null,
      leadProductId: ProductId || null,
      leadType: leadType || null,
      nextFollowUpDate: nextFollowUpDate || null,
      nextFollowUpTime: nextFollowUpTime || null,
      remarks: remark || null,
      // interaction_type: interactionType || null,
      interaction_type: callId ? null : interactionType || null,
    };
    const res = await addDisposition(payload);
    if (res?.data?.status_code === 200) {
      dispositionSaveClick();
      statusUpdate();
      setIsSaving(false);
      handleSaveClick();
      getShowCallDetails();
      resetHandler();
      dropCallFun();
      handleDispositionSubmitted();

      displayToast({
        type: "success",
        title: res?.data?.message,
      });
    } else {
      displayToast({
        type: "error",
        title: res?.data?.message,
      });
    }
  };

  useEffect(() => {
    getDispositionDropdownOption();
  }, [getDispositionDropdownOption]);

  useEffect(() => {
    getLeadTypeDropdownOption();
  }, [getLeadTypeDropdownOption]);

  useEffect(() => {
    if (dispositionID) {
      getSubDispositionDropDown(dispositionID);
    }
  }, [dispositionID, getSubDispositionDropDown]);

  return (
    // <form onSubmit={handleSubmit(onSubmit)} className="mainContainer">
    <div>
      <div className="row align-item-center">
        <div className="col-4">
          <CustomDropdown
            label={"Call Disposition"}
            // disabled={!callId || !isInitiateCallButtonShow}
            options={dispositoinOption}
            placeholder="Select Call Disposition"
            value={dispositionID}
            error={errors.callDisposition}
            onChange={(e) => {
              setDispositionId(Number(e.target.value));
            }}
          />
        </div>
        <div className="col-4">
          <CustomDropdown
            label={"Sub Disposition"}
            options={subDispositionOption}
            // disabled={!callId || !isInitiateCallButtonShow}
            placeholder="Select Sub Disposition"
            value={subDispositionId}
            onChange={(e) => {
              setSubDispositionid(Number(e.target.value));
            }}
          />
        </div>
        <div className="col-4">
          <CustomDropdown
            // disabled={!callId || !isInitiateCallButtonShow}
            label={"Lead Type"}
            options={LeadTypeIdOption}
            name={"leadTypeId"}
            placeholder="Select Lead Type"
            value={leadType || ""}
            optionLabel={"label"}
            optionValue={"value"}
            onChange={(e) => setLeadType(Number(e.target.value))}
          />
        </div>
      </div>

      {/* ------------2nd row--------- */}
      <div className="row align-item-center mt-3">
        {/* <div className="form-group mt-3"> */}
        <div className="col-4">
          <label className="form-label">Next Follow up Date</label>
          <CustomDateInput
            // disabled={!callId || !isInitiateCallButtonShow}
            isDateRange={false}
            selected={nextFollowUpDate}
            name={"nextFollowUpDate"}
            minDate={Today}
            // register={register("nextFollowUpDate")}
            onChange={(date) => {
              // const formattedDate = moment(date).format("DD-MM-YYYY");
              const formattedDate = moment(date, [
                "YYYY-MM-DD",
                "YYYY-DD-MM",
                "DD-MM-YYYY",
              ]).format("yyyy-MM-DD");
              setNextFollowUpDate(formattedDate);
            }}
          />
        </div>
        <div className="col-4">
          <label className="form-label">Next Follow up Time</label>
          <CustomDateInput
            // disabled={!callId || !isInitiateCallButtonShow}
            dateFormat="hh:mm aa"
            isDateRange={false}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText="Enter Time"
            // selected={nextFollowUpTime}
            selected={
              nextFollowUpTime
                ? moment(nextFollowUpTime, "hh:mm A").toDate()
                : null
            }
            name={"nextFollowUpTime"}
            // register={register("nextFollowUpTime")}
            onChange={(date) => {
              const formattedTime = moment(date).format("HH:mm");
              setNextFollowUpTime(formattedTime);
            }}
          />
        </div>
        <div className="col-4">
          <div>Remark</div>
          <textarea
            // disabled={!callId || !isInitiateCallButtonShow}
            id="remarks"
            name="remarks"
            className="form-control remark-form mt-2"
            rows="1"
            // value={formData["remarks"]}
            value={remark}
            // onChange={handleFormUpdates}
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            typeof={"text"}
            placeholder="Text here...."
          ></textarea>
        </div>
      </div>
      <div className="d-flex justify-content-end btn-save">
        <CustomButton
          className="save-btn fw-bold me-2 mt-3 dis-save"
          type="submit"
          onClick={dispositionSave}
          disabled={
            // !(callId || isInitiateCallButtonShow === "Without Initiate Call")
            !(callId || interactionType)
          }
          // title={!callId ? "Please initiate call" : ""}
        >
          {/* {isSaving ? "Saving..." : "Save"} */}
          Save
        </CustomButton>
      </div>
      <Modal show={successPopup} onHide={() => setSuccessPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Data saved successfully!</Modal.Body>
        <Modal.Footer>
          <CustomButton onClick={() => setSuccessPopup(false)}>
            Close
          </CustomButton>
        </Modal.Footer>
      </Modal>
      {/* </form> */}
    </div>
  );
};

export default CallDetailsComponent;
