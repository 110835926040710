import React, { useEffect, useState } from "react";
import "./CustomTable.scss";
import CustomPagination from "../CustomPagination";
import { ShimmerTable } from "react-shimmer-effects";

const CustomTable = (props) => {
  const {
    // selectedRows,
    tableColumn = [],
    tableRow = [],
    isSelectedCheckbox = false,
    onChackingClick = () => {},
    // isTableStriped = true,
    // onActionClick = () => {},
    isPaginationTablePaginationShow = true,
    isTableClicksEnabled = false,
    peginations = [],
    onPeginations = () => {},
    narrowTable = false,
    bgClassName = "",
    onRowClick = () => {},
    tableLoaderShow = false,
    selectedCheckings,
  } = props;
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState();


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableRow.map((n) => n);
      setSelectedRow(newSelected);
      onChackingClick(newSelected);
    } else {
      setSelectedRow([]);
      onChackingClick([]);
    }
  };

  const handleCheckBoxClick = (event, row) => {
    event?.stopPropagation();
    const selectedIndex = selectedRow
      ?.map((value) => value.id)
      ?.indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRow, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRow.slice(1));
    } else if (selectedIndex === selectedRow?.length - 1) {
      newSelected = newSelected.concat(selectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRow.slice(0, selectedIndex),
        selectedRow.slice(selectedIndex + 1)
      );
    }
    setSelectedRow(newSelected);
    onChackingClick(newSelected);
  };

  const handleSelectBoxClick = (event, row) => {
    event.stopPropagation();
    const selectedIndex = selectedRow.map((value) => value.id).indexOf(row.id);
    if (selectedIndex === -1) {
      setSelectedRow([row]);
    } else {
      // setSelectedRow([]);
    }
  };

  const isSelected = (id) =>
    selectedRow?.map((value) => value?.id).indexOf(id) !== -1;

  useEffect(() => {
    if (isSelectedCheckbox && (selectedCheckings || tableLoaderShow)) {
      console.log("selectedCheckings", selectedCheckings);
      setSelectedRow(selectedCheckings || []);
    }
  }, [isSelectedCheckbox, selectedCheckings, tableLoaderShow]);

  return (
    <>
      {tableLoaderShow ? (
        <ShimmerTable
          mode="custom"
          row={5}
          col={7}
          border={1}
          borderColor={"#374151"}
          rounded={0.25}
          rowGap={16}
          colPadding={[10, 5, 10, 5]}
        />
      ) : (
        <>
          <div
            className={`table-container overflow-x-auto
             ${narrowTable ? "narrow-table" : ""}`}
          >
            <table
              className={`table custom-table-sub-container ${
                narrowTable ? "narrow-table" : ""
              }

          `}
              // ${isTableStriped ? "table-striped" : ""}
            >
              <thead className="custom-table-header-container ">
                <tr className="custom-table-column-container ">
                  {isSelectedCheckbox && (
                    <th
                      className={`text-center p-2 table-collumn-header-container ${bgClassName} removreWhitespace`}
                    >
                      <input
                        type="checkbox"
                        className="customTableCheckbox"
                        size={24}
                        onChange={(event) => {
                          handleSelectAllClick(event);
                        }}
                        checked={
                          tableRow?.length > 0 &&
                          selectedRow?.length === tableRow?.length
                        }
                        // indeterminate={
                        //   selectedRow?.length > 0 &&
                        //   selectedRow?.length < tableRow?.length
                        // }
                      />
                    </th>
                  )}
                  {tableColumn?.map((value, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className={`table-collumn-header-container removreWhitespace ${bgClassName}
                           ${
                             value?.position === "center"
                               ? "text-center"
                               : value?.position === "end"
                               ? "text-end"
                               : "text-start"
                           }
                           ${value?.nowrap ? "text-nowrap" : ""}
                           `}
                      >
                        <div
                          className={`
                          text-capitalize
                          ${
                            value?.renderVertical ? "d-flex" : ""
                          } align-items-center p-2 justify-content-center
                          `}
                          // ${
                          //   value?.position === "center"
                          //     ? "justify-content-center"
                          //     : value?.position === "end"
                          //     ? "justify-content-end"
                          //     : "justify-content-start"
                          // }
                        >
                          {value?.label}
                          <div>
                            {typeof value.render === "function" &&
                              value.render(value)}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {tableRow?.length > 0 && (
                <tbody className="custom-table-body-container">
                  {tableRow?.map((rowData, index) => {
                    const isItemSelected = isSelected(rowData?.id);

                    return (
                      <tr
                        key={index}
                        className={`custom-table-row-container ${
                          isTableClicksEnabled ? "table-click-enabled" : ""
                        } ${isItemSelected ? "isRowSelected" : ""}`}
                        onClick={(e) => {
                          if (isTableClicksEnabled) {
                            // e.preventDefault();
                            e.stopPropagation();
                            onRowClick(rowData, index);
                            handleSelectBoxClick(e, rowData);
                          }
                        }}
                      >
                        {isSelectedCheckbox && (
                          <td
                            className={`h-100 text-center
                             ${isItemSelected ? "isRowSelected" : ""}`}
                            style={{ minWidth: "50px" }}
                          >
                            <input
                              type="checkbox"
                              className="customTableCheckbox"
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleCheckBoxClick(event, rowData)
                              }
                            />
                          </td>
                        )}
                        {tableColumn?.map((tableCol, indexcol) => {
                          if (tableCol?.isRenderItem) {
                            const data = rowData[tableCol?.rowId];
                            return (
                              <td
                                className={`custon-button-action ${
                                  tableCol?.rowDataPosition === "center"
                                    ? "d-flex justify-content-center align-item-center"
                                    : ""
                                } ${
                                  tableCol?.position === "center"
                                    ? "text-center"
                                    : tableCol?.position === "end"
                                    ? "text-end"
                                    : "text-start"
                                }
                                 ${isItemSelected ? "isRowSelected" : ""}
                                 `}
                                key={tableCol?.id}
                              >
                                {data}
                              </td>
                            );
                          } else {
                            const data = rowData[tableCol?.rowId];
                            if (
                              typeof data === "object" &&
                              rowData[tableCol?.rowId]?.length
                            ) {
                              return <td className="" key={tableCol?.id}></td>;
                            }
                            return (
                              <td
                                key={tableCol?.id}
                                className={`
                                  ${tableCol?.nowrap ? "text-nowrap" : ""}
                                 ${isItemSelected ? "isRowSelected" : ""}
                                  ${
                                    tableCol?.position === "center"
                                      ? "text-center"
                                      : tableCol?.position === "end"
                                      ? "text-end"
                                      : "text-start"
                                  }`}
                                style={{
                                  padding: "5px",
                                }}
                              >
                                {data}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>

            {!tableRow?.length && (
              <div className="d-flex justify-content-center vh-25">
                <div className="w-100">
                  {/* <img src={NoData} alt="No Data" /> */}
                  No Records Found
                </div>
              </div>
            )}
          </div>

          {isPaginationTablePaginationShow && tableRow?.length > 0 && (
            <div className="d-flex justify-content-end p-2 borderLightBlue">
              {/* -----------changes started----- */}
              {/* <select
                value={rowsPerPage}
                // onChange={handleRowsPerPageChange}
                // className="form-select w-auto justify-content-between"
              >
                {[10, 20, 30, 50, 100].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select> */}
              <CustomPagination
                rowsPerPage={rowsPerPage}
                pagination={{
                  currentPage: peginations?.currentPage || 1,
                  totalResult: peginations?.total || 10,
                  perPage: peginations?.perPage || 10,
                }}
                onPeginationHandler={(data) => {
                  // console.log("data", data);
                  // setCurrentPage({
                  //   ...currentPage,
                  //   page: data,
                  // });
                  onPeginations(data);
                }}
                currentPage={peginations?.currentPage}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CustomTable;

// import React, { useEffect, useState } from "react";
// import "./CustomTable.scss";
// import CustomPagination from "../CustomPagination";
// import { ShimmerTable } from "react-shimmer-effects";
// import { useDispatch } from "react-redux";
// import {
//   setleadProductId,
//   setLeadId,
// } from "../../../redux/reducers/selectedIdsSlice";
// import { dispatch } from "../../../redux";

// const CustomTable = (props) => {
//   const {
//     getRowData,
//     selectedRows,
//     tableColumn = [],
//     tableRow,
//     isSelectedCheckbox = false,
//     onClick = () => {},
//     isTableStriped = true,
//     // onActionClick = () => {},
//     isSelectedRenderGroup = () => {},
//     isPaginationTablePaginationShow = true,
//     isTableClicksEnabled = false,
//     peginations = [],
//     onPeginations = () => {},
//     narrowTable = false,
//     bgClassName = "",
//     onRowClick = () => {},
//     tableLoaderShow = false,
//     onSearch = () => {},
//   } = props;
//   const [selectedRow, setSelectedRow] = useState([]);
//   // const [selectedIds, setSelectedIds] = useState([]);
//   // const dispatch = useDispatch();
//   // console.log("dispatch", dispatch);

//   const handleDataUserSearchHandler = (e) => {
//     onSearch(e?.target?.value);
//     // const { value } = e.currentTarget;
//     // dispatch(dataLiveFilter(value,'name',tableData));
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelected = tableRow.map((n) => n);
//       setSelectedRow(newSelected);
//     } else {
//       setSelectedRow([]);
//     }
//   };

//   const handleCheckBoxClick = (event, row) => {
//     event?.stopPropagation();
//     const selectedIndex = selectedRow?.map((value) => value.id).indexOf(row.id);
//     let newSelected = [];
//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selectedRow, row);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selectedRow.slice(1));
//     } else if (selectedIndex === selectedRow?.length - 1) {
//       newSelected = newSelected.concat(selectedRow.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selectedRow.slice(0, selectedIndex),
//         selectedRow.slice(selectedIndex + 1)
//       );
//     }
//     setSelectedRow(newSelected);
//   };

//   // ----------- Redux Logic --------------

//   useEffect(() => {
//     const leadId = selectedRow.map((selectedRow) => selectedRow.leadId);
//     dispatch(setLeadId(leadId));
//     // console.log("checkbox map leadId", leadId);

//     const leadProductIds = selectedRow.map((selectedRow) => selectedRow.id);
//     dispatch(setleadProductId(leadProductIds));
//     // console.log("checkbox map leadProductIds", leadProductIds);
//   }, [selectedRow]);

//   const handleSelectBoxClick = (event, row) => {
//     event.stopPropagation();
//     const selectedIndex = selectedRow.map((value) => value.id).indexOf(row.id);
//     // console.log("checkbox id --><--", row.id);
//     if (selectedIndex === -1) {
//       setSelectedRow([row]);
//     } else {
//       // setSelectedRow([]);
//     }
//   };

//   const isSelected = (id) =>
//     selectedRow?.map((value) => value?.id).indexOf(id) !== -1;

//   return (
//     <>
//       {tableLoaderShow ? (
//         <ShimmerTable
//           row={5}
//           style={{ margin: "10px" }}
//           col={tableColumn?.length - 1 || 5}
//         />
//       ) : (
//         <>
//           <div
//             className={`table-container overflow-x-auto
//              ${narrowTable ? "narrow-table" : ""}`}
//           >
//             <table
//               className={`table custom-table-sub-container ${
//                 narrowTable ? "narrow-table" : ""
//               }

//           `}
//               // ${isTableStriped ? "table-striped" : ""}
//             >
//               <thead className="custom-table-header-container ">
//                 <tr className="custom-table-column-container ">
//                   {isSelectedCheckbox && (
//                     <th
//                       className={`text-center p-2 table-collumn-header-container ${bgClassName} removreWhitespace`}
//                     >
//                       <input
//                         type="checkbox"
//                         className="customTableCheckbox"
//                         size={24}
//                         onChange={(event) => {
//                           handleSelectAllClick(event);
//                         }}
//                         checked={
//                           tableRow?.length > 0 &&
//                           selectedRow?.length === tableRow?.length
//                         }
//                         indeterminate={
//                           selectedRow?.length > 0 &&
//                           selectedRow?.length < tableRow?.length
//                         }
//                       />
//                     </th>
//                   )}
//                   {tableColumn?.map((value, index) => {
//                     return (
//                       <th
//                         key={index}
//                         scope="col"
//                         className={`table-collumn-header-container removreWhitespace ${bgClassName}
//                            ${
//                              value?.position === "center"
//                                ? "text-center"
//                                : value?.position === "end"
//                                ? "text-end"
//                                : "text-start"
//                            }
//                            ${value?.nowrap ? "text-nowrap" : ""}
//                            `}
//                       >
//                         <div
//                           className={`
//                           text-capitalize
//                           ${
//                             value?.renderVartical ? "d-flex" : ""
//                           } align-items-center p-2 justify-content-center
//                           `}
//                           // ${
//                           //   value?.position === "center"
//                           //     ? "justify-content-center"
//                           //     : value?.position === "end"
//                           //     ? "justify-content-end"
//                           //     : "justify-content-start"
//                           // }
//                         >
//                           {value?.label}
//                           <div>
//                             {typeof value.render === "function" &&
//                               value.render(value)}
//                           </div>
//                         </div>
//                       </th>
//                     );
//                   })}
//                 </tr>
//               </thead>

//               {tableRow?.length > 0 && (
//                 <tbody className="custom-table-body-container">
//                   {tableRow?.map((rowData, index) => {
//                     const isItemSelected = isSelected(rowData?.id);

//                     return (
//                       <tr
//                         key={index}
//                         className={`custom-table-row-container ${
//                           isTableClicksEnabled ? "table-click-enabled" : ""
//                         } ${isItemSelected ? "isRowSelected" : ""}`}
//                         onClick={(e) => {
//                           if (getRowData) {
//                             console.log("#@#@#@ I was Invoked", rowData);
//                             getRowData(rowData);
//                           }
//                           if (isTableClicksEnabled) {
//                             // e.preventDefault();
//                             e.stopPropagation();
//                             onRowClick(rowData, index);
//                             handleSelectBoxClick(e, rowData);
//                           }
//                         }}
//                       >
//                         {isSelectedCheckbox && (
//                           <td
//                             className={`h-100 text-center fw-9
//                              ${isItemSelected ? "isRowSelected" : ""}`}
//                             style={{ minWidth: "50px" }}
//                           >
//                             <input
//                               type="checkbox"
//                               className="customTableCheckbox"
//                               checked={isItemSelected}
//                               onChange={(event) => {
//                                 handleCheckBoxClick(event, rowData);
//                               }}
//                             />
//                           </td>
//                         )}
//                         {tableColumn?.map((tableCol, indexcol) => {
//                           if (tableCol?.isRenderItem) {
//                             const data = rowData[tableCol?.rowId];
//                             return (
//                               <td
//                                 className={`custon-button-action  ${
//                                   tableCol?.position === "center"
//                                     ? "text-center"
//                                     : tableCol?.position === "end"
//                                     ? "text-end"
//                                     : "text-start"
//                                 }
//                                  ${isItemSelected ? "isRowSelected" : ""}
//                                  `}
//                                 key={tableCol?.id}
//                               >
//                                 {data}
//                               </td>
//                             );
//                           } else {
//                             const data = rowData[tableCol?.rowId];
//                             if (
//                               typeof data === "object" &&
//                               rowData[tableCol?.rowId]?.length
//                             ) {
//                               return <td className="" key={tableCol?.id}></td>;
//                             }
//                             return (
//                               <td
//                                 key={tableCol?.id}
//                                 className={`
//                                   ${tableCol?.nowrap ? "text-nowrap" : ""}
//                                  ${isItemSelected ? "isRowSelected" : ""}
//                                   ${
//                                     tableCol?.position === "center"
//                                       ? "text-center"
//                                       : tableCol?.position === "end"
//                                       ? "text-end"
//                                       : "text-start"
//                                   }`}
//                                 style={{
//                                   padding: "5px",
//                                 }}
//                               >
//                                 {data}
//                               </td>
//                             );
//                           }
//                         })}
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               )}
//             </table>

//             {!tableRow?.length && (
//               <div className="d-flex justify-content-center align-items-center ">
//                 <div className="w-100">
//                   {/* <img src={NoData} alt="No Data" /> */}
//                   No Records Found
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* {console.log("peginations table", peginations)} */}

//           {isPaginationTablePaginationShow && tableRow?.length > 0 && (
//             <div className="d-flex justify-content-end p-2 borderLightBlue">
//               <CustomPagination
//                 pagination={{
//                   currentPage: peginations?.currentPage || 1,
//                   totalResult: peginations?.total || 10,
//                   perPage: peginations?.perPage || 10,
//                 }}
//                 onPeginationHandler={(data) => {
//                   // console.log("data", data);
//                   onPeginations(data);
//                   // setCurrentPage({
//                   //   ...currentPage,
//                   //   page: data,
//                   // });
//                 }}
//                 currentPage={peginations?.currentPage}
//               />
//             </div>
//           )}
//         </>
//       )}
//     </>
//   );
// };

// export default CustomTable;
