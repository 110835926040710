import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { EXIST_TABLE_LIST } from "../InboundListDefaultData";
import ExistLeadTableList from "./ExistLeadTableList";
import { APIInboundMobNumber } from "../../../../../api/common";
import { useNavigate } from "react-router-dom";
import ROUTES_NAVIGATION from "../../../../../routes/routes";

const ExistingLeadModelComponant = ({
  mobileNumber,
  existLeadList,
  searchResponse = () => {},
}) => {
  //   const [existLeadList, setExistLeadList] = useState([]);

  const navigate = useNavigate();

  //   const getRecAgainstMobNumber = useCallback(async () => {
  //     const paylaod = {
  //       mobile_no: mobileNumber || null,
  //     };
  //     const res = await APIInboundMobNumber(paylaod);
  //     if (res.ok) {
  //       setExistLeadList(res?.data?.data || []);
  //     } else {
  //       searchResponse(res);
  //       setExistLeadList([]);
  //     }
  //   }, [mobileNumber, searchResponse]);

  //   useEffect(() => {
  //     getRecAgainstMobNumber();
  //   }, [getRecAgainstMobNumber]);

  return (
    <div>
      <p>
        <small>
          <strong> Receiving Incoming Call from +91 - {mobileNumber} </strong>
          <br />
          Our records indicate that this number is associated with the following
          entries. Please select one to proceed
        </small>
      </p>
      <ExistLeadTableList
        tableRowData={existLeadList}
        onMobileNumberClick={(row) => {
          navigate(
            ROUTES_NAVIGATION.VEHICLE_INSURENCE_TOTALLEAD +
              "?productId=" +
              row?.product_id +
              "&leadId=" +
              row?.leadId +
              "&details=" +
              "leadDetails"
          );
        }}
      />
    </div>
  );
};

export default ExistingLeadModelComponant;
